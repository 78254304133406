import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Auth0Provider, AppState } from '@auth0/auth0-react'
import { AUTH0_DOMAIN, AUTH0_CLIENT_ID, API_URL } from '../config'
import axios from 'axios'

export interface Auth0ProviderWithHistoryProps {
  children: React.ReactChild
}

//FUNCTION TO FETCH THE USER RELATED DATA FROM LOCAL STORAGE
export function authStorage() {

  var data = null,
    keys = Object.keys(localStorage),
    i = keys.length;

  while (i--) {
    if (keys[i]?.includes("@@auth0spajs@@"))
      data = JSON.parse(localStorage.getItem(keys[i]));
  }

  return data;
}

export default function Auth0ProviderWithHistory({ children }: Auth0ProviderWithHistoryProps) {
  const history = useHistory()

  const onRedirectCallback = async (appState: AppState) => {
    //firefox workaround
    window.location.hash = window.location.hash // eslint-disable-line no-self-assign
    history.push(appState?.returnTo || window.location.pathname);
    //CALLING SET USER METHOD
    await setUserHandler();
  }

  //FUNCTION TO CREATE AN ENTRY IN THE BACKEND USERS TABLE IF THE USER IS LOGGED IN FOR THE FIRST TIME
  const setUserHandler = async () => {
    const data = authStorage();
    if (!data) return;
    const { client_id, decodedToken } = data?.body;
    const user = { client_id, ...decodedToken?.user };
    //SETTING DEFAULT EMAIL AUTHORIZATION
    axios.defaults.headers.common['Authorization'] = user?.email;
    try {
      const res: any = await axios.post(`${API_URL}/api/ctb/set-user`, user);
      localStorage.setItem("ctb_user", JSON.stringify(res?.user));
    } catch (err) {
      console.log(err);
    }
  }

  const redirectUriPath = window.location.pathname === '/' ? '/home' : window.location.pathname

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      useRefreshTokens={true}
      cacheLocation={'localstorage'}
      redirectUri={`${window.location.origin}${redirectUriPath}`}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}
