import axios from 'axios'
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import UploadSection from './UploadSection'
import LastUploadedSection from '../components/LastUploadedSection'
import RegularColumnSelection from '../components/RegularColumnSelection'
import Grid from '@material-ui/core/Grid'

import { secondaryFormatter } from './utils'
import { hasAllRequiredColumnValues } from './SourceDataMethods'
import { API_URL } from '../../../config'
import { firstLetterUpper } from '../../../util/stringTransform'
import toast from 'react-hot-toast'
import { logError } from '../../../util/logger'

const useStyles = makeStyles(() => ({
  breakLine: {
    margin: 10,
    marginTop: 20,
    marginBottom: 20,
  },
  root: {},
}))

const ForecasetSourceData = ({ uploadSummary, refetchData, setExpand }) => {
  const classes = useStyles()

  const currentColumnRef = uploadSummary?.columnRef
  const currentSheetSelected = uploadSummary?.sheetNameToSelect
  const currentUploadedFile = uploadSummary?.fileName
  const lastImported = uploadSummary?.lastImported
  const fileStructure = uploadSummary?.fileStructure
  const fileToken = uploadSummary?.fileToken
  const status = uploadSummary?.status
  const statusMessage = uploadSummary?.statusMessage

  const [sheet, setSheet] = useState(currentSheetSelected || '')
  const [currentColumnList, setCurrentColumnList] = useState(currentSheetSelected ? secondaryFormatter(fileStructure, currentSheetSelected) : [])
  const [partNumber, setPartNumber] = useState(currentColumnRef?.partNumber || '')
  const [dateOfRecord, setDateOfRecord] = useState(currentColumnRef?.dateOfRecord || '')
  const [quantity, setQuantity] = useState(currentColumnRef?.quantity || '')
  const [disableSave, setDisableSave] = useState(true)

  const setColumnData = (inputSheet, sheet) => {
    setCurrentColumnList(secondaryFormatter(inputSheet.fileStructure, sheet))
  }

  const changeSheet = (sheetName) => {
    if (sheetName === '') {
      setCurrentColumnList([])
    } else setColumnData(uploadSummary, sheetName)
    setSheet(sheetName)
  }

  const criticalColumnConfig = [
    {
      label: 'Part Number',
      ref: partNumber,
      refSet: setPartNumber,
      required: true,
    },
    {
      label: 'Date',
      ref: dateOfRecord,
      refSet: setDateOfRecord,
      required: true,
    },
    {
      label: 'Production Target Qty',
      ref: quantity,
      refSet: setQuantity,
      required: true,
    },
  ]
  const allRequiredFieldsSelected = hasAllRequiredColumnValues(criticalColumnConfig)
  const handleFieldChange = (setStateFn, value) => {
    setStateFn(value)
    if (disableSave) setDisableSave(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const setForecastRefAPIPath = `${API_URL}/api/dataSource/v1/setForecastColumnRef`
    const data = {
      fileToken: fileToken,
      cleanImport: true,
      sheetName: sheet,
      columnRef: {
        partNumber: partNumber,
        dateOfRecord: dateOfRecord,
        quantity: quantity,
      },
    }

    const updateForecastColumnRef = axios
      .post(setForecastRefAPIPath, data)
      .then(async () => {
        await refetchData(true);
        setDisableSave(true)
        setExpand(false);
      })
      .catch((err) => {
        toast.error('Somethign went wrong while trying to save your new selection. Please try again')
        logError(err.message, err.stack)
      })

    toast.promise(updateForecastColumnRef, {
      loading: <div>{`Processing new Forecast Data, this will replace your old forecast data.`}</div>,
      success: <b>Updated!</b>,
      error: <b>We had an issue saving your changes, please try again!</b>,
    })
  }

  return (
    <Grid container spacing={2} direction='column' className={classes.root}>
      <UploadSection uploadDataType='forecast' refetchSourceInfoFn={refetchData} setExpand={setExpand} />
      <hr className={classes.breakLine} />

      <LastUploadedSection
        fileName={currentUploadedFile}
        lastImported={lastImported}
        status={firstLetterUpper(status)}
        statusMessage={statusMessage}
        label={`Forecast`}
      />
      <hr className={classes.breakLine} />

      <RegularColumnSelection
        config={criticalColumnConfig}
        changeSheet={changeSheet}
        currentSheet={sheet}
        fileStructure={fileStructure}
        currentColumnList={currentColumnList}
        handleFieldChange={handleFieldChange}
        handleSubmit={handleSubmit}
        allRequiredFieldsSelected={allRequiredFieldsSelected}
        disableSave={disableSave}
      />
    </Grid>
  )
}

export default ForecasetSourceData
