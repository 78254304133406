import React from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

export const statusEnum = [
  { value: 'Pending', label: 'Pending' },
  { value: 'Received', label: 'Received' },
]

const DeliveryTableStatusEdit = (props) => {
  const { onChange: editChange, rowData } = props

  return (
    <Select
      variant='outlined'
      defaultValue={'Pending'}
      value={rowData.receivedStatus}
      autoWidth
      style={{ fontSize: 15, width: 120 }}
      onChange={(e) => editChange(e.target.value)}
    >
      {statusEnum?.map((v) => (
        <MenuItem key={v.value} value={v.value}>
          {v.value}
        </MenuItem>
      ))}
    </Select>
  )
}

export default React.memo(DeliveryTableStatusEdit)
