// import { useState } from 'react'
import { useQuery } from 'react-query'
import axios from 'axios'
import { API_URL } from '../../config'
import { rqDefaultOptions } from './defaultOptions'

const getProductionForecast = async (inputObj) => {
  const { parentPart, weeksOut } = inputObj.queryKey[1]
  const parentPartAPIPath = `${API_URL}/api/ctb/v1/productionForecast?parentPart=${parentPart.partNumber}&weeksOut=${weeksOut}`
  const res = await axios.get(parentPartAPIPath)
  return res.data
}

const getCTBForecast = async (inputObj) => {
  const { parentPart, weeksOut } = inputObj.queryKey[1]
  const parentPartAPIPath = `${API_URL}/api/ctb/v1/ctbDelivery?parentPart=${parentPart.partNumber}&weeksOut=${weeksOut}`
  const res = await axios.get(parentPartAPIPath)
  return res.data
}

export function useProductionForecast(parentPart, setProductionForecast, weeksOut = 20) {
  const options = {
    enabled: !!parentPart,
    onSuccess: (data) => {
      setProductionForecast(data)
    },
    ...rqDefaultOptions,
  }
  const ppQuery = useQuery(['productionForecast', { parentPart, weeksOut }], getProductionForecast, options)
  return ppQuery
}

export function useCTBForecast(parentPart, setCTBForecast, weeksOut = 20) {
  const options = {
    enabled: !!parentPart,
    onSuccess: (data) => {
      setCTBForecast(data)
    },
    ...rqDefaultOptions,
  }
  const ctbQuery = useQuery(['ctbDelivery', { parentPart, weeksOut }], getCTBForecast, options)
  return ctbQuery;
}