import { useQuery } from 'react-query'
import axios from 'axios'
import { API_URL } from '../../../config'
import { rqDefaultOptions } from '../defaultOptions'

const getPartTable = async (inputObj) => {
  const { rootPartId } = inputObj.queryKey[1]
  const partTableAPIPath = `${API_URL}/api/part/all?rootPartId=${rootPartId ?? ''}`
  const res = await axios.get(partTableAPIPath)
  return res.data
}

export function usePartTable(queryKey, rootPartId) {
  const options = {
    ...rqDefaultOptions,
  }
  return useQuery([queryKey, { rootPartId }], getPartTable, options)
}
