export const firstLetterUpper = (inputStr) => {
  if (!inputStr) return inputStr
  const strArr = inputStr.split(' ')
  for (let i = 0; i < strArr.length; i++) {
    let str = strArr[i]
    if (str.length) {
      strArr[i] = str[0].toUpperCase() + str.substring(1)
    }
  }

  return strArr.join(' ')
}
