import React from 'react'
import { useHistory } from 'react-router'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import useStyles from './LoginStyles'
import { useAuth0 } from '@auth0/auth0-react'

const Login = () => {
  const classes = useStyles()
  const history = useHistory()

  const { loginWithRedirect, isAuthenticated, user, isLoading } = useAuth0()

  if (isAuthenticated) history.push(`${window.location.origin}/home`)

  return (
    <Grid container direction='row' component='main' spacing={2} className={classes.root}>
      {/* <CssBaseline /> */}
      <Grid item xs={false} sm={6} md={6} className={classes.image}>
        <Typography className={classes.floatingTitle1} component='h1' variant='h3'>
          Kaizoft
        </Typography>
        <Typography className={classes.floatingTitle2} component='h1' variant='h4'>
          Clear To Build
        </Typography>
        <Typography className={classes.floatingTitle3} component='h1' variant='h4'>
          Visualize Your Supply Chain
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={6}
        md={6}
        className={classes.paper}
        component={Paper}
        elevation={6}
        direction='column'
        spacing={1}
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid container item xs={12} spacing={1} direction='row' alignItems='center' justifyContent='center'>
          <Grid container item xs={12} spacing={4} direction='column' alignItems='center' justifyContent='center'>
            <Grid item xs={12}>
              <Typography variant='h3'>Welcome Back!</Typography>
            </Grid>
            <Grid item xs={12}>
              <Button id='auth0LoginButton' variant='contained' className={classes.submit} onClick={() => loginWithRedirect()}>
                Sign In
              </Button>
            </Grid>
            <br/>
            <Grid container item xs={12} spacing={4} direction='column' alignItems='center' justifyContent='center'>
            <span>Don't have an account? <a href="https://kaizoft.com/clear-to-build/" style={{color: '#1E6ADB'}} color='blue'>Click Here</a></span>
            </Grid>
            <br/>
            <Grid container item xs={12} spacing={4} direction='column' alignItems='center' justifyContent='center'>
            <span>Back to Kaizoft <a style={{color: "#1E6ADB"}} href="https://kaizoft.com/">Homepage</a></span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Login
