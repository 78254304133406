import React, { useState } from 'react'
import InventoryTable from './components/InventoryTable'
import InventorySourceData from './sections/InventorySourceData'
import { useInventorySourceSummary } from '../../hooks/queries/sourceSummary'
import { useInventoryTable } from '../../hooks/queries/inventory/inventoryTable'
import SourceAccordians from './components/SourceAccordian/SourceAccordians'
import { useQueryClient } from 'react-query'
import Loading from '../../components/Loading'

export const InventoryPage = () => {
  const [sheetData, setSheetData] = useState([])
  const [expanded, setExpanded] = useState([false, 0]);

  const sourceSummaryType = 'inventorySummary'
  const sourceSummaryQueryKey = 'inventorySourceSummary'
  const dataSummaryQueryKey = 'inventoryTable'
  const pageDataType = 'inventory'

  const { isFetching: iFSourceData } = useInventorySourceSummary(sourceSummaryQueryKey, sourceSummaryType, {
    setSheetData,
  })

  const {
    isLoading: iLTableData,
    isError: iETableData,
    isFetching: iFTableData,
    data: invTableData,
  } = useInventoryTable(dataSummaryQueryKey, 1000, 0)

  const queryClient = useQueryClient()

  const refetchData = () => {
    queryClient.refetchQueries([sourceSummaryQueryKey])
    queryClient.refetchQueries([dataSummaryQueryKey])
  }
  const clearSheetData = () => {
    setSheetData([])
  }

  const setExpand = (Val) => {
    setExpanded([Val, sheetData.length - 1]);
  }

  const sourceDataArr = iFSourceData || !Array.isArray(sheetData)
    ? []
    : sheetData?.map((sheet, i) => {
      return <InventorySourceData key={i} refetchData={refetchData} uploadSummary={sheet} label={sheet.stockName} setExpand={setExpand} />
    })

  const addNewSourceFn = (refetchData) => {
    setSheetData([...sheetData, <InventorySourceData refetchData={refetchData} key={sheetData.length} uploadSummary={{}} label={'New'} setExpand={setExpand} />])
  }

  const tableData = iLTableData || iETableData || iFTableData ? [] : invTableData

  return (
    <>
      {iFSourceData ? (
        <Loading />
      ) : (
        <SourceAccordians
          label={'Inventory'}
          refetchData={refetchData}
          sources={sourceDataArr}
          clearSheetData={clearSheetData}
          displayComp={<InventoryTable data={tableData} />}
          addNewSource={true}
          addToSourceFn={addNewSourceFn}
          sourceSummaryQueryKey={sourceSummaryQueryKey}
          dataSummaryQueryKey={dataSummaryQueryKey}
          pageDataType={pageDataType}
          toExpand={expanded}
        />
      )}
    </>
  )
}
