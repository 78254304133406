import { ENV } from './config'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Toaster } from 'react-hot-toast'
import CustomTheme from './theme/CustomTheme'
import { toastStyles } from './styles/toastStyles'
import Auth0ProviderWithHistory from './context/Auth0ProviderWithHistory'
import LoginGateway from './view/auth/LoginGateway'
import './app.scss'

const queryClient = new QueryClient()

function App() {
  return (
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <QueryClientProvider client={queryClient}>
          <CustomTheme>
            <LoginGateway />
          </CustomTheme>
          <Toaster
            toastOptions={{
              style: toastStyles,
            }}
          />
          {ENV !== 'production' && <ReactQueryDevtools />}
        </QueryClientProvider>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  )
}

export default App
