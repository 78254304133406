import React from 'react'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const CustomTheme = ({ children }) => {
  const theme = createTheme({
    typography: {
      fontFamily: ['Nunito', 'sans-serif'].join(','),
    },
    palette: {
      primary: {
        light: '#f59778',
        main: '#f37e57',
        dark: '#aa583c',
        contrastText: '#fff',
      },
      secondary: {
        light: '#37474f',
        main: '#62727b',
        dark: '#102027',
        contrastText: '#ffffff',
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1120,
        lg: 1280,
        xl: 1920,
      },
    },
    props: {
      MuiTextField: {
        variant: 'outlined',
      },
      MuiLink: {
        underline: 'always',
      },
    },
  })

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

CustomTheme.propTypes = {
  children: PropTypes.any,
}

export default CustomTheme
