import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Select, MenuItem } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: '100%',
  },
  selectEmpty: {
    height: 40,
    fontSize: 15,
  },
  dropDownCont: {
    paddingTop: 15,
    paddingLeft: 10,
  },
}))

export default function ColumnSelect(inputProps) {
  const classes = useStyles()

  const { changeSheet, fileStructure, currentColumnList, handleFieldChange, config, currentSheet } = inputProps

  return (
    <Grid conatiner={'true'} className={classes.dropDownCont}>
      <Grid container spacing={2} direction='row' justifyContent='flex-start' alignItems='center'>
        <Grid item xs={5}>
          <Typography className={classes.columnName}>
            Select Sheet
            <sup style={{ color: 'red' }}> *</sup>
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <FormControl className={classes.formControl}>
            <Select variant='outlined' fullWidth value={currentSheet} className={classes.selectEmpty} onChange={(e) => changeSheet(e.target.value)}>
              <MenuItem value=''>Select Sheet</MenuItem>
              {fileStructure?.map(({ name }, i) => (
                <MenuItem key={`${name}-${i}`} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {config.map(({ label, ref, refSet, required }, index) => (
        <Grid container key={`${label}-${index}`} spacing={2} direction='row' justifyContent='flex-start' alignItems='center'>
          <Grid item xs={5}>
            <Typography className={classes.columnName}>
              {label}
              <sup style={{ color: 'red' }}>{required ? ' *' : ''}</sup>
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <FormControl className={classes.formControl}>
              <Select
                variant='outlined'
                fullWidth
                value={ref}
                onChange={(e) => handleFieldChange(refSet, e.target.value)}
                className={classes.selectEmpty}
              >
                <MenuItem value={``}>None</MenuItem>
                {currentColumnList?.map((v) => (
                  <MenuItem key={v.value} value={v.value}>
                    {v.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}
