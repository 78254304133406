import React, { useState, useEffect } from 'react'
import SourceAccordians from './components/SourceAccordian/SourceAccordians'
import SourceAccordianMain from './components/SourceAccordian/SourceAccordianMain'
import ProductActualTable from './components/ProductActualsTable'
import ActualsSourceData from './sections/ActualsSourceData'
import { useProductionAcutalsSourceSummary } from '../../hooks/queries/sourceSummary'
import { useActualsData } from '../../hooks/queries/forecastTable'
import { useQueryClient } from 'react-query'
import Loading from '../../components/Loading'
import { Accordion, AccordionDetails, AccordionSummary, responsiveFontSizes, ThemeProvider, Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import toast from 'react-hot-toast'
import { API_URL } from '../../config'
import axios from 'axios'

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 20,
    paddingBottom: 0,
  },
  accDeets: {
    padding: 20,
    paddingTop: 10,
    paddingLeft: 40,
  },
  heading: {
    fontSize: '1.2rem',
    fontWeight: 500,
  },
  formLabel: {
    color: "gray",
    "&.Mui-focused": {
      color: "gray"
    }
  }
}))

export const ProductionActualsPage = () => {
  const [sheetData, setSheetData] = useState(null)
  const [radio, setRadio] = useState('no');
  const [isDisabled, setDisabled] = useState(false);
  
  useEffect(() => {
    setDisabled(true);
    axios
    .get(`${API_URL}/api/actuals/v1/getWeeklyProductions`)
    .then((response) => {
      setRadio(response.data.data);
    });
  }, []);

  const sourceSummaryType = 'actualsSummary'
  const sourceSummaryQueryKey = 'actualsSourceSummary'
  const dataSummaryQueryKey = 'actualsTable'
  const pageDataType = 'actuals'

  const { isLoading: iLSourceData, isFetching: iFSourceData } = useProductionAcutalsSourceSummary(sourceSummaryQueryKey, sourceSummaryType, {
    setSheetData,
  })


  const {
    isLoading: iLTableData,
    isError: iETableData,
    isFetching: iFTableData,
    data: actualsTableData,
  } = useActualsData(dataSummaryQueryKey, 1000, 0)

  const queryClient = useQueryClient()
  const refetchData = () => {
    queryClient.refetchQueries([sourceSummaryQueryKey])
    queryClient.refetchQueries([dataSummaryQueryKey])
  }

  const clearSheetData = () => {
    setSheetData({})
  }

  const OnRadioChange = (event) => {
    setDisabled(false);
    setRadio(event.target.value);
  }

  const OnSaveClick = (event) => {
    // call api to save the 'radio' value into orgsettings
    const end_point = `${API_URL}/api/actuals/v1/setWeeklyProductions`;
    const data = {
      criteria: radio
    }

    const mainToast = toast.loading(<div>{`Please wait...`}</div>)
    const setWeeklyProductions = axios
    .post(end_point, { criteria: radio})
    .then(async () => {
      toast.success(<b>Updated!</b>, {
        id: mainToast
      });
      setDisabled(true);
    })
    .catch(async (err) => {
      toast.error(<b>Could not set Weekly Production criteria!</b>, {
        id: mainToast
      });
    })
  }

  const sourceDataSources = iLSourceData ? [] : [<ActualsSourceData refetchData={refetchData} uploadSummary={sheetData} key={0} />]

  const tableData = iLTableData || iETableData || iFTableData ? [] : actualsTableData

  const classes = useStyles();


  return (
    <>
      {iFSourceData ? (
        <Loading />
      ) : ([
        <Container className={classes.root}>
          <Accordion style={{borderRadius: '15px', overflow: 'hidden'}} >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="weekly-content" id="weekly-id">
              <Typography className={classes.heading}>
                Weekly Production Actuals
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accDeets}>
              <FormControl>
                <FormLabel classes={{ root: classes.formLabel }} id="actuals-label">
                  Clear to build calculations can be adjusted to account for actual weekly build quantities using the settings below
                </FormLabel>
                <RadioGroup
                  aria-labelledby="actuals-label"
                  defaultValue="no"
                  name="radio-buttons-group"
                  value={radio}
                  onChange={OnRadioChange}
                >
                  <FormControlLabel value="no" control={<Radio />} label="Do not account for actual production" />
                  <FormControlLabel value="linear" control={<Radio />} label="Assume linear current week production relative to target" />
                  <FormControlLabel disabled={true} value="valued" control={<Radio />} label="Import actual weekly production values" />
                </RadioGroup>
                <br />
                <Button disabled={isDisabled} style={{borderRadius: 10, paddingLeft: 10, paddingRight: 10, width: '100px'}} onClick={OnSaveClick} sx={{ mt: 1, mr: 1}} variant='contained' color='primary' fullWidth='false' size='medium' >Save</Button>
              </FormControl>
            </AccordionDetails>
          </Accordion>
        </Container>,
        <SourceAccordians
          label={'Actuals'}
          sources={sourceDataSources}
          refetchData={refetchData}
          clearSheetData={clearSheetData}
          displayComp={<ProductActualTable data={tableData} />}
          sourceSummaryQueryKey={sourceSummaryQueryKey}
          dataSummaryQueryKey={dataSummaryQueryKey}
          pageDataType={pageDataType}
        />
      ])}
    </>
  )
}
