import React, { useEffect, useState } from 'react'
import { SingleBom } from './components/SingleBom'
import { useParentParts } from '../../hooks/queries/useParentParts'
import { useAuth0 } from '@auth0/auth0-react'
import Grid from '@material-ui/core/Grid'
import GenericLoading from '../../components/GenericLoading'
import { Container, Paper, Typography } from '@material-ui/core'
import ProductSelection from '../../components/ProductSelection'

export const BillOfMaterialsPage = () => {
  const [rootPart, setRootPart] = useState();
  const [parentPartList, setParentPartList] = useState([])
  const { user } = useAuth0();
  const ppQuery = useParentParts(setRootPart, setParentPartList, user?.email);

  function resetProductList() {
    setRootPart(undefined);
    ppQuery.refetch();
  }

  if (!ppQuery.isFetched) {
    return (
      <Grid container direction='row' spacing={1} alignItems='center' justifyContent='center'>
        <Grid item>
          <GenericLoading />
        </Grid>
      </Grid>
    )
  }

  const { data: rootParts } = ppQuery;
  const newPart = {partDescription: "Add New BOM"};

  return <>
    <Container>
      <Paper style={{ marginTop: 20, padding: 16, borderRadius: 15, display: 'flex', alignItems: 'center' }}>
        <Typography style={{width: '100%', fontSize: '1.2rem', fontWeight: 500,}}>Select BOM:</Typography>
        <ProductSelection
          options={rootParts}
          newOptionDescription='Add New BOM'
          setOption={setRootPart}
          currentOption={rootPart}
        />
      </Paper>
    </Container>
    <SingleBom rootPart={rootPart} resetProductList={resetProductList} />
  </>
}
