// eslint-disable

import React, { useCallback, useMemo, useState, useEffect } from 'react'

import MaterialTableCustom from '../../../components/MaterialTableCustom'
import { useUpdateDelivery, useAddDelivery, useDeleteDelivery } from '../../../hooks/queries'
import DeliveryTableReceivedStatusEdit from '../../../components/MaterialTableCustomComps/DeliveryTableStatusEdit'
// import DeliveryTableCarrierEdit from '../../../components/MaterialTableCustomComps/DeliveryTableCarrierEdit'
// import { trackingStatus } from '../../../components/MaterialTableCustomComps/DeliveryTableTypes'
// import DeliveryTableTrackingLink from '../../../components/MaterialTableCustomComps/DeliveryTableTrackingLink'

const DeliveriesTable = ({ data, refreshTableData }) => {
  const { mutateAsync: addDelivery } = useAddDelivery()
  const { mutateAsync: updateDelivery } = useUpdateDelivery()
  const { mutateAsync: deleteDelivery } = useDeleteDelivery()
  const [deliveryData, setDeliveryData] = useState(data)

  useEffect(() => {
    if (!data) return
    //MAPPING THROUGH THE DATA AND FORMATTING IT AS PER REQUIRED BY THE TABLE
    const new_data = data.sort((a, b) => new Date(a?.dateOfRecord) - new Date(b?.dateOfRecord)).map((d) => ({
      ...d,
      trackingNumber: d?.tracking?.trackingNumber,
      carrier: d?.tracking?.carrier,
      trackingStatus: d?.tracking?.status,
    }))
    setDeliveryData([...new_data])
  }, [data])

  const onRowAdd = useCallback(async (newData) => {
    await addDelivery(newData)
    refreshTableData()
  }, [])

  const onRowUpdate = useCallback(async (newData) => {
    await updateDelivery(newData)
    refreshTableData()
  }, [])

  const onRowDelete = useCallback(async (oldData) => {
    await deleteDelivery({ id: oldData.id })
    refreshTableData()
  }, [])

  const columns = useMemo(
    () => [
      { title: 'PN', field: 'partNumber' },
      {
        title: 'Description', field: 'bomDescription', editable: false
      },
      {
        title: 'Delivery Date',
        field: 'dateOfRecord',
        type: 'date',
      },
      { title: 'Qty', field: 'quantity', type: 'numeric' },
      { title: 'Supplier', field: 'vendorName' },
      //ADDED TRACKING NUMBER AND TRACKING STATUS COLUMNS
      {
        title: 'Tracking #',
        field: 'trackingNumber',
        // render: (rowData) => {
        //   if (!rowData.trackingNumber || rowData.trackingStatus === trackingStatus.ERROR) return rowData.trackingNumber
        //   else return <DeliveryTableTrackingLink rowData={rowData} />
        // },
      },
      // {
      //   title: 'Carrier',
      //   field: 'carrier',
      //   editComponent: DeliveryTableCarrierEdit,
      // },
      {
        title: 'Tracking Status',
        field: 'trackingStatus',
        editable: false,
      },
      {
        title: 'Received Status',
        field: 'receivedStatus',
        editComponent: DeliveryTableReceivedStatusEdit,
      },
    ],
    [],
  )
  return (
    <MaterialTableCustom
      title={'Deliveries Summary'}
      columns={columns}
      rowStyle={{
        fontSize: '12px',
        paddingTop: 0,
        paddingBottom: 0,
        height: 0,
      }}
      headerStyle={{
        marginTop: -5,
        paddingTop: 0,
        paddingBottom: 5,
      }}
      cellStyle={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: 135,
      }}
      data={deliveryData}
      options={{
        filtering: false,
        search: true,
        paging: false,
        actionsColumnIndex: 10,
        maxBodyHeight: '600px',
      }}
      editable={{
        onRowAdd,
        onRowUpdate,
        onRowDelete,
      }}
    />
  )
}

export default DeliveriesTable
