/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import Chart from 'react-apexcharts'
import { SectionHeader } from '../../../components/Title'
import { useCTBForecast, useProductionForecast } from '../../../hooks/queries/productionForecast'
import { useHomePageContext } from '../../../context/HomePageProvider'
import GenericLoading from '../../../components/GenericLoading'
import Grid from '@material-ui/core/Grid'
import { API_URL } from '../../../config'
import axios from 'axios'

const formatter = (data, type, ) => {
  const test = []
  switch (type) {
    case 'weeklyTarget':
      data?.map((v,i) => {test.push(v.weeklyTarget)})
      return test

    case 'cumulativeTarget':
      data?.map((v) => test.push(v.cumulativeTarget))
      return test
    
    case 'ClearToBuild':
      data?.map((v) => test.push(v.cumulativeTarget))
      return test

    case 'date':
      data?.map((v) => test.push(v.date))
      return test

    default:
      console.log(`Sorry, we are out of ${type}.`)
  }
}

const getSeries = (productionForecastResponse, CTBForecastResponse) => {
  return [
    {
      type: 'bar',
      name: 'Weekly Target',
      data: formatter(productionForecastResponse, 'weeklyTarget'),
    },
    {
      type: 'line',
      name: 'Cumulative Target',
      data: formatter(productionForecastResponse, 'cumulativeTarget'),
    },
    {
      type: 'line',
      name: 'Actual CTB',
      data: formatter(CTBForecastResponse, 'ClearToBuild'),
    }
  ]
}

const colors = ['#8F8BFF', '#F37E57', '#16C098', '#000000']

const getChartDataOptions = (productionForecastResponse, CTBForecastResponse, ) => {
  return {
    options: {
      colors: colors,
      chart: {
        id: 'basic-bar',
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: formatter(CTBForecastResponse, 'date'),
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
        },
      },
      yaxis: [
        {
          min: 0,
          forceNiceScale: true,
          title: {
            text: 'Weekly Target',
            style: {
              color: colors[3],
              fontFamily: 'Nunito',
              fontWeight: 'normal',
            },
          },
        },
        {
          min: 0,
          forceNiceScale: true,
          opposite: true,
          title: {
            text: 'Cumulative',
            style: {
              color: colors[3],
              fontFamily: 'Nunito',
              fontWeight: 'normal',
            },
          },
        },
        {
          show: false,
          seriesName: 'Cumulative Target',
          axisTicks: {
            show: true,
          },
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          min: 0,
          forceNiceScale: true,
          opposite: true,
          title: {
            text: "",
          },
          tooltip: {
            enabled: false,
          },
        },
      ],

      legend: {
        position: 'top',
        horizontalAlign: 'left',
        fontFamily: 'Nunito',
      },
    },

    series: getSeries(productionForecastResponse, CTBForecastResponse),

    yaxis: [
      {
        seriesName: 'Weekly Target',
      },
      {
        seriesName: 'Cumulative Target',
      },
      {
        seriesName: 'Actual CTB',
      },
    ],
  }
}

const ProductinForecast = ({ parentPart }) => {
  const [productionForecastResponse, setForecast] = useState(null)
  const [CTBForecastResponse, setCTBForecast] = useState(null);
  const { weeksOut } = useHomePageContext()

  const query = useProductionForecast(parentPart, setForecast, weeksOut.val);
  const query2 = useCTBForecast(parentPart, setCTBForecast, weeksOut.val);

  if (query.isLoading || query2.isLoading ) {
    <Grid container direction='row' spacing={1} alignItems='center' justifyContent='center'>
      <Grid item>
        <GenericLoading />
      </Grid>
    </Grid>
  }

  return (
    <div className='bg-white rounded-lg shadow-sm p-3'>
      <div>
        <SectionHeader className='mb-2' heading='Production Forecast' />
      </div>

      <div className='mixed-chart mx-n2'>
        <Chart
          options={getChartDataOptions(productionForecastResponse, CTBForecastResponse).options}
          series={getChartDataOptions(productionForecastResponse, CTBForecastResponse).series}
          width='100%'
          height='300px'
        />
      </div>
    </div>
  )
}

export default ProductinForecast