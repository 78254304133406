export const formatter = (data) => {
  return data?.map((v) => ({
    value: v.name,
    label: v.name,
  }))
}

export const secondaryFormatter = (data, type) => {
  return data
    ?.filter((v) => v.name === type)[0]
    .columns.map((i) => ({
      value: i,
      label: i,
    }))
}
