import React from 'react'
import ChipInput from 'material-ui-chip-input'
import { useAddPartExclusion, useRemovePartExclusion } from '../../../hooks/queries/exclusions'
import { Typography } from '@material-ui/core'

export default function ExcludePartsChips({ dataArray, refetchData }) {
  const { mutateAsync: addPartAPI } = useAddPartExclusion()
  const { mutateAsync: removePartAPI } = useRemovePartExclusion()

  const handleAddChip = async (chips) => {
    const excludeAPIInput = chips.split(' ').map((chip) => ({
      partNumber: chip,
      excludeOnlyChildren: true,
    }))

    await addPartAPI({ partNumbers: excludeAPIInput })
    refetchData()
  }

  const handleDeleteChip = async (chip) => {
    await removePartAPI({ partNumbers: [chip] })
    refetchData()
  }

  return (
    <>
      <Typography className='exclusion-title'>Exclude only the child parts of these parent parts</Typography>
      <ChipInput
        className={'chip-input'}
        placeholder={`Paste a List of part numbers or type one to exclude`}
        alwaysShowPlaceholder
        fullWidthInput
        variant={'outlined'}
        value={dataArray}
        onAdd={(chip) => handleAddChip(chip)}
        onDelete={(chip, index) => handleDeleteChip(chip, index)}
      />
    </>
  )
}
