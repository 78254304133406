import 'react'
import { useMutation, useQuery } from 'react-query'
import axios from 'axios'
import { API_URL } from '../../config'
import { rqDefaultOptions } from './defaultOptions'

export const addPartExclusion = async ({ partNumbers }) => {
  if (!partNumbers) throw 'Supply part numbers to delete'
  const addPartExclusionsAPIPath = `${API_URL}/api/part/v1/exclusions/add`
  return await axios.put(addPartExclusionsAPIPath, partNumbers)
}

export function useAddPartExclusion() {
  return useMutation(addPartExclusion)
}

export const removePartExclusion = async ({ partNumbers }) => {
  if (!partNumbers) throw 'Supply part numbers to delete'
  const removePartExclusionsAPIPath = `${API_URL}/api/part/v1/exclusions/remove`
  return await axios.put(removePartExclusionsAPIPath, partNumbers)
}

export function useRemovePartExclusion() {
  return useMutation(removePartExclusion)
}

const getPartExclusions = async () => {
  const getPartExclusionsAPIPath = `${API_URL}/api/part/v1/exclusions`
  const res = await axios.get(getPartExclusionsAPIPath)
  return res.data
}

export function useGetPartExclusion(queryKey) {
  const options = {
    ...rqDefaultOptions,
  }
  return useQuery([queryKey], getPartExclusions, options)
}
