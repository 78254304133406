import React, { useState, useEffect } from 'react'
import { format, parseISO } from 'date-fns'
import Popover from '@material-ui/core/Popover'
import axios from 'axios'
import { API_URL } from '../config'
import './partPopup.scss'

const PartPopup = ({ partNumber, setAnchorEl, anchorEl }) => {
  const [partInfo, setPartInfo] = useState({})

  const handleClose = () => {
    setAnchorEl(null)
  }
  useEffect(() => {
    if (partNumber) {
      const partInfoAPIPath = `${API_URL}/api/part/partInfo?partNumber=${partNumber}`
      axios
        .get(partInfoAPIPath)
        .then((res) => {
          setPartInfo(res.data)
        })
        .catch((err) => console.log(err))
    }
  }, [partNumber])
  if (partInfo.partNumber) {
    return (
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className='popup-container'>
          <div className='popup-header'>
            <p>
              {partInfo.partNumber} {partInfo.description}
            </p>
          </div>
          <div className='popup-info'>
            <p style={{ fontWeight: 'bold' }}>Inventory Summary</p>
            <table className='popup-table-table'>
              <tr>
                <th></th>
                {partInfo.stockNames.map((st, i) => {
                  return (
                    <th className='popup-table-header' key={i}>
                      {st}
                    </th>
                  )
                })}
              </tr>
              <tr>
                <td>Unrestricted</td>
                {Object.keys(partInfo.stockType).map((st, i) => {
                  return (
                    <td className='popup-table-value' key={i}>
                      {partInfo.stockType[st].regular}
                    </td>
                  )
                })}
              </tr>
              <tr>
                <td>Restricted</td>
                {Object.keys(partInfo.stockType).map((st, i) => {
                  return (
                    <td className='popup-table-value' key={i}>
                      {partInfo.stockType[st].qualityCheck}
                    </td>
                  )
                })}
              </tr>
              <tr>
                <td>Total</td>
                {Object.keys(partInfo.stockType).map((st, i) => {
                  return (
                    <td className='popup-table-value' key={i}>
                      {partInfo.stockType[st].regular + partInfo.stockType[st].qualityCheck}
                    </td>
                  )
                })}
              </tr>
            </table>
            <br />
            {partInfo.deliveries && partInfo.deliveries.length > 0 ? (
              <>
                <p style={{ fontWeight: 'bold' }}>Deliveries Summary</p>
                <table className='popup-table-table'>
                  <tr>
                    <th>Date</th>
                    <th className='popup-table-header'>Qty</th>
                    <th className='popup-table-header'>Supplier</th>
                    <th className='popup-table-header'></th>
                  </tr>
                  {partInfo.deliveries.map((delivery, i) => {
                    return (
                      <tr key={i}>
                        <td>{format(parseISO(delivery.dateOfRecord), 'MM/dd/yyyy')}</td>
                        <td className='popup-table-value'>{delivery.quantity}</td>
                        <td className='popup-table-value'>{delivery.vendorName}</td>
                        <td className='popup-table-value'></td>
                      </tr>
                    )
                  })}
                </table>
              </>
            ) : null}
          </div>
        </div>
      </Popover>
    )
  } else {
    return <></>
  }
}

export default PartPopup
