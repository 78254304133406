import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import RoundedButton from '../../../components/RoundedButton'
import Typography from '@material-ui/core/Typography'
import ColumnSelection from './ColumnSelection'

const useStyles = makeStyles(() => ({
  header: {
    paddingBottom: 10,
  },
  root: {
    paddingTop: 10,
    paddingBottom: 20,
    maxWidth: 700,
  },
}))

export default function ExtraColumnSelection(inputProps) {
  const classes = useStyles()

  const {
    changeSheet,
    fileStructure,
    currentColumnList,
    handleSubmit,
    handleFieldChange,
    config,
    currentSheet,
    allRequiredFieldsSelected,
    disableSave,
    leftSideComp,
    isUpdating,
  } = inputProps

  return (
    <div className={classes.root}>
      <Grid container spacing={2} direction='row' justifyContent='space-between' alignItems='center' className={classes.header}>
        <Grid item>
          <Typography variant='h6'>Critical Column Selection</Typography>
        </Grid>
        <Grid item>
          <RoundedButton
            onClick={handleSubmit}
            variant='contained'
            color='primary'
            type='submit'
            isLoading={isUpdating}
            disabled={isUpdating === true || !allRequiredFieldsSelected || disableSave ? true : undefined}
          >
            Save
          </RoundedButton>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction='row' justifyContent='space-between' alignItems='center'>
        <Grid item xs={6}>
          {leftSideComp}
        </Grid>
        <Grid item xs={6}>
          <ColumnSelection
            changeSheet={changeSheet}
            fileStructure={fileStructure}
            currentColumnList={currentColumnList}
            handleFieldChange={handleFieldChange}
            config={config}
            currentSheet={currentSheet}
          />
        </Grid>
      </Grid>
    </div>
  )
}
