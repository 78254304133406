import React from 'react'
import MaterialTableCustom from '../../../components/MaterialTableCustom'

const parentChildData = (row, rows) =>
  rows.find((currRowSearch) => {
    for (const parent in row.parents) {
      if (currRowSearch.id === parent) return true
    }
    return false
  })

export default function BomTable({ data }) {
  if (!data) return undefined

  const title = 'BOM Preview'

  const formattedColumns = [
    { field: 'partNumber', title: 'PN' },
    {
      field: 'partType',
      title: 'Type',
    },
    {
      field: 'description',
      title: 'Description',
    },
    {
      field: 'bomQty',
      title: 'Qty',
      render: (rowData) => {
        // every part should only have one parent, extract part out of it
        const { parents } = rowData
        const rowParent = Object.keys(rowData.parents)
        if (rowParent.length) {
          return parents[rowParent[0]]
        }
        return ''
      },
    },
  ]

  const rowStyle = {
    fontSize: `13px`,
  }

  return <MaterialTableCustom title={title} columns={formattedColumns} data={data} rowStyle={rowStyle} parentChildData={parentChildData} />
}
