// import { useState } from 'react'
import { useQuery } from 'react-query'
import axios from 'axios'
import { API_URL } from '../../config'
import { rqDefaultOptions } from './defaultOptions'
import { useAuth0 } from '@auth0/auth0-react'

const getParentParts = async (email) => {
  const parentPartAPIPath = `${API_URL}/api/part/v1/parentParts`
  axios.defaults.headers.common['Authorization'] = email;
  const res = await axios.get(parentPartAPIPath).catch((err) => {
    throw err
  })
  return res.data
}

export function useParentParts(setParentPart, setParentPartList, email) {
  const options = {
    onSuccess: (data) => {
      setParentPartList(data)
      if (data.length) setParentPart(data[data.length-1])
    },
    ...rqDefaultOptions,
  }
  const ppQuery = useQuery(['parentParts'], () => getParentParts(email), options)
  return ppQuery
}
