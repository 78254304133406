import axios from 'axios'
import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { createStyles, Theme, withStyles, WithStyles, makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import ToolTip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import MuiDialogActions from '@material-ui/core/DialogActions'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import './partMapDialog.scss'
import { Tooltip } from '@material-ui/core'
import BackupIcon from '@material-ui/icons/Backup'
import RoundedButton from '../../../components/RoundedButton'
import { API_URL } from '../../../config'
import toast from 'react-hot-toast'
import { logError } from '../../../util/logger'

const useStyles = makeStyles((theme) => ({
  inputStyles: {
    borderRadius: 15,
    '& MuiInputBase-input': {
      borderRadius: 15,
    },
    // overflow: 'hidden',
  },
  InputCont: {
    // flexGrow: 2,
  },
  root: {
    maxWidth: 700,
  },
  input: {
    display: 'none',
  },
  uploadRow: {
    paddingBottom: 10,
    paddingTop: 10,
  },
  uploadImage: {
    color: theme.palette.primary.light,
    borderRadius: 15,
  },
  uploadLine: {
    marginLeft: 10,
  },
  uploadText: {
    marginLeft: 10,
    fontSize: '0.7rem',
  },
}))
const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

export interface DialogTitleProps extends WithStyles<typeof styles> {
  onClose: () => void
  children: React.ReactNode
  id: string
}

export interface PartMapDialogProps {
  handleFieldChange: <T>(setStateFn: React.Dispatch<T>, value: T) => void
  aliasPartMapToken: string
  setAliasPartMapToken: React.Dispatch<string>
  aliasPartMapFileName: string
  setAliasPartMapFileName: React.Dispatch<string>
}

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    marginLeft: 'auto',
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: 10,
  },
}))(MuiDialogActions)

export default function PartMapUploadDialog(props: PartMapDialogProps) {
  const { setAliasPartMapToken, aliasPartMapFileName, setAliasPartMapFileName, handleFieldChange } = props

  const [open, setOpen] = useState(false)
  const [file, setFile] = useState()
  const [fileName, setFileName] = useState('')
  const [disableUpload, setDisableUpload] = useState(true)
  // const [isUploading, setIsUploading] = useState(false)

  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleSave = () => {
    handleFileUpload()
    setOpen(false)
  }

  const handleFileSet = (e) => {
    setFileName(e.target?.files[0]?.name)
    setFile(e.target.files[0])
    setDisableUpload(false)
  }

  const removePartAlias = () => {
    handleFieldChange(setAliasPartMapFileName, '')
    handleFieldChange(setAliasPartMapToken, '')
  }

  const handleFileUpload = async () => {
    const uploadAPIPath = `${API_URL}/api/dataSource/v1/uploadFile/partMap`
    // setIsUploading(true)
    const formData = new FormData()
    formData.append('uploadFile', file)
    axios
      .post(uploadAPIPath, formData)
      .then((res) => {
        setFile(null)
        setFileName('')
        const token: string = res?.data?.fileToken || ''
        const fileName: string = res?.data?.fileName || ''
        console.log(res.data)
        handleFieldChange(setAliasPartMapToken, token)
        handleFieldChange(setAliasPartMapFileName, fileName)
        // setIsUploading(false)
      })
      .catch((err) => {
        toast.error('Oops! Something was odd with your file upload. Please try again', { duration: 10000, style: { minWidth: 500 } })
        // setIsUploading(false)
        logError(err.message, err.stack)
      })
  }

  const randomRefNumber = Math.random().toString(36).substring(7)

  return (
    <>
      <Grid
        container
        spacing={2}
        direction='column'
        wrap='nowrap'
        justifyContent='flex-start'
        alignItems='flex-start'
        className='part-map-upload-dialog-cont'
      >
        <Grid item>
          <Typography variant='h6'>Part Number Map</Typography>
        </Grid>

        {aliasPartMapFileName && (
          <Grid item className='part-map-upload-filename-cont' style={{ marginBottom: -15 }}>
            <Typography variant='body1' className='part-map-upload-filename'>
              {`Part Map: '${aliasPartMapFileName}'`}
              <ToolTip title='Remove Current Part Map'>
                <IconButton aria-label='close' size='small' onClick={removePartAlias}>
                  <HighlightOffRoundedIcon />
                </IconButton>
              </ToolTip>
            </Typography>
          </Grid>
        )}
        <Grid item>
          <Button className='part-map-upload-dialog-openbutton' variant='contained' color='primary' onClick={handleClickOpen}>
            {aliasPartMapFileName ? 'Change Part Map' : 'Upload New Part Map'}
          </Button>
        </Grid>
      </Grid>

      <form>
        <Dialog fullWidth className='part-map-upload-dialog-main' onClose={handleClose} aria-labelledby='Upload Part Map Dialog' open={open}>
          <DialogTitle id='customized-dialog-title' onClose={handleClose}>
            {aliasPartMapFileName ? 'Change Part Map' : 'Upload New Part Map'}
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2} direction='column' alignItems='center'>
              <Grid item>
                <Typography variant='caption' className='part-map-upload-information' gutterBottom>
                  {`
              A valid part map file will can be an excel workbook (.xlsx) or a .csv file. In the case of an excel workbook the file must have one sheet only. In both cases the sheet should contain two columns and a header. The first column should be the BOM Part Number, and the second column should be the manufacturing/alias part number`}
                </Typography>
              </Grid>
              <Grid item>
                <input
                  className={classes.input}
                  type='file'
                  accept='.csv,.xlsx'
                  name='file'
                  id={`fileUpload-${randomRefNumber}`}
                  onChange={(e) => handleFileSet(e)}
                />
                <Tooltip title='Upload Part Map File'>
                  <label htmlFor={`fileUpload-${randomRefNumber}`} style={{ marginBottom: 0 }}>
                    <Grid container direction='row' alignItems='center' justifyContent='center'>
                      <Button className={classes.uploadImage} color='primary' aria-label='upload picture' component='span'>
                        <BackupIcon fontSize='medium' />
                        <Typography className={classes.uploadText}>{fileName || 'Select File To Upload'}</Typography>
                      </Button>
                    </Grid>
                  </label>
                </Tooltip>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <RoundedButton
              className='part-map-upload-button'
              style={{ borderRadius: 10 }}
              onClick={() => handleSave()}
              variant='contained'
              color='secondary'
              disabled={disableUpload}
            >
              Upload
            </RoundedButton>
          </DialogActions>
        </Dialog>
      </form>
    </>
  )
}
