import { useQuery } from 'react-query'
import axios from 'axios'
import { API_URL } from '../../config'
import { rqDefaultOptions } from './defaultOptions'

const getAllDeliveries = async (inputObj) => {
  const { limit, skip, email } = inputObj.queryKey[1];
  axios.defaults.headers.common['Authorization'] = email;
  const allDeliveriesAPIPath = `${API_URL}/api/delivery/v1/getAllDeliveries?limit=${limit}&skip=${skip}`
  const res = await axios.get(allDeliveriesAPIPath);
  return res.data
}

export function useAllDeliveries(queryKey, limit = 20, skip = 0, email) {
  const options = {
    ...rqDefaultOptions,
  }
  return useQuery([queryKey, { limit, skip, email }], getAllDeliveries, options)
}
