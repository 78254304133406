import { useQuery } from 'react-query'
import axios from 'axios'
import { API_URL } from '../../../config'
import { rqDefaultOptions } from '../defaultOptions'

const getForecastData = async (inputObj) => {
  const { limit, skip, dataSourceId } = inputObj.queryKey[1]

  const forecastTableAPIPath = `${API_URL}/api/forecast/v1/getForecastData?limit=${limit}&skip=${skip}&dataSourceId=${dataSourceId ?? ''}`
  const res = await axios.get(forecastTableAPIPath);
  return res.data
}

export function useForecastTableData(queryKey, dataSourceId, limit, skip) {
  const options = {
    ...rqDefaultOptions,
  }
  return useQuery([queryKey, { dataSourceId, limit, skip }], getForecastData, options)
}
