import React from 'react'
import MaterialTableCustom from '../../../components/MaterialTableCustom'

const baseColumns = [
  { field: 'partNumber', title: 'PN' },
  {
    field: 'partType',
    title: 'Type',
  },
  {
    field: 'description',
    title: 'Description',
  },
  {
    field: 'availableStock',
    title: 'Total Qty',
  },
]

const makeColumnArr = (data) => {
  if (!data || !data.length) return []

  const columns = []

  // let count = 0;

  // const included = new Set();

  // for (const key in data[0]) {
  //   if (count >= 6 && count % 2 == 0) {
  //     let nz = true;
  //     for(let i = 1; i < data.length; i++) {
  //       if (data[i][count] !== 0) {
  //         nz = false;
  //         break;
  //       }
  //     }
  //     if (nz) {
  //       included.add(count);
  //     }
  //   }
  //   count += 1;
  // }

  // count = 0;

  for (const key in data[0]) {
    if (key.includes('Inv--')) {
      const label = key.substring(5)
      columns.push({
        field: key,
        title: label,
      })
    }
  }

  return [...baseColumns, ...columns]
}

const InventoryTable = ({ data }) => {
  if (!data) return undefined

  const title = 'Inventory Summary'

  const formattedColumns = makeColumnArr(data)

  const rowStyle = {
    fontSize: `13px`,
  }


  return (
    <>
      <MaterialTableCustom title={title} columns={formattedColumns} data={data} rowStyle={rowStyle} />
    </>
  )
}

export default InventoryTable
