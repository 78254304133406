import React from 'react'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import PartMapUploadDialog from './PartMapDialog'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  breakLine: {
    margin: 10,
    marginTop: 10,
    marginBottom: 10,
  },
}))
export default function ExtraConfigInventory(inputProps) {
  const classes = useStyles()
  const {
    handleFieldChange,
    stockName,
    setStockName,
    aggregateSumQty,
    setAggregateSumQty,
    // clearOtherInventory,
    // setClearOtherInventory,
    aliasPartMapToken,
    setAliasPartMapToken,
    aliasPartMapFileName,
    setAliasPartMapFileName,
  } = inputProps

  return (
    <Grid container spacing={2} direction='column' justifyContent='space-between'>
      <Grid item>
        <TextField
          label='Stock Name'
          placeholder='Set Inventory Name'
          variant='outlined'
          value={stockName}
          required
          size='small'
          onChange={(e) => handleFieldChange(setStockName, e.target.value)}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={aggregateSumQty}
              onChange={(e) => handleFieldChange(setAggregateSumQty, e.target.checked)}
              size='medium'
              color='primary'
            />
          }
          label={`Aggregate Part Qty`}
        />
      </Grid>
      <hr className={classes.breakLine} />

      <Grid item>
        <PartMapUploadDialog
          handleFieldChange={handleFieldChange}
          aliasPartMapFileName={aliasPartMapFileName}
          setAliasPartMapFileName={setAliasPartMapFileName}
          aliasPartMapToken={aliasPartMapToken}
          setAliasPartMapToken={setAliasPartMapToken}
        />
      </Grid>
    </Grid>
  )
}
