/* eslint-disable react/display-name */
import React, { useMemo, useEffect } from 'react'
// import MaterialTable from 'material-table'
import MaterialTableCore from '@material-table/core'
import { tableIcons } from './MaterialTableIcons'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { makeStyles } from '@material-ui/core'

const defaultOptions = {
  filtering: false,
  search: true,
  rowStyle: {
    fontSize: `14px`,
    textAlign: 'center',
    height: `30px`,
  },
  paging: false,
  addRowPosition: 'first',
  headerStyle: {
    backgroundColor: 'white',
    position: 'sticky',
    top: 0,
  },
}

const useStyles = makeStyles(() => ({
  dateWrapper: {
    '& .MuiInputBase-input': {
      fontSize: 13,
    },
  },
}))

export const MTDateInputComponent = React.memo((props) => {
  const classes = useStyles()
  const { onChange, dateFormat = 'MM/dd/yyyy', value } = props

  return (
    <div className={classes.dateWrapper}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          variant='normal'
          format={dateFormat}
          style={{ fontSize: '12px !important' }}
          value={value}
          onChange={(value) => onChange(value)}
        />
      </MuiPickersUtilsProvider>
    </div>
  )
})

const addPropsToColumns = (columnArr) => {
  for (const col of columnArr) {
    const { title, type, dateFormat = 'MM/dd/yyyy' } = col
    if (type === 'date') {
      col.editComponent = (props) => <MTDateInputComponent title={title} dateFormat={dateFormat} {...props} />
    }
  }
}
export default function MaterialTableCustom({
  columns: iColumns,
  title: iTitle,
  components: iComponents,
  options = {},
  rowStyle,
  cellStyle,
  headerStyle,
  data: iData,
  editable,
  parentChildData,
}) {
  const combinedOptions = {
    ...defaultOptions,
    ...options,
  }

  if (rowStyle) combinedOptions.rowStyle = { ...combinedOptions.rowStyle, ...rowStyle }
  if (rowStyle) combinedOptions.headerStyle = { ...combinedOptions.headerStyle, ...headerStyle }
  if (cellStyle) combinedOptions.cellStyle = { ...combinedOptions.cellStyle, ...cellStyle }

  addPropsToColumns(iColumns)
  const columns = useMemo(() => iColumns, [iColumns])
  const title = useMemo(() => iTitle, [iTitle])
  const components = useMemo(() => iComponents, [iComponents])
  const data = useMemo(() => iData, [iData])

  return (
    <MaterialTableCore
      title={title}
      components={components}
      icons={tableIcons}
      columns={columns}
      data={data}
      options={combinedOptions}
      editable={{ ...editable }}
      parentChildData={parentChildData}
    />
  )
}
