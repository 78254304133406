import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { useCTBTable } from '../../../hooks/queries/clearToBuildTable'
import MaterialTableCustom from '../../../components/MaterialTableCustom'
import PartPopup from '../../../components/PartPopup'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import CTBTableToolBar from './CTBTableToolBar'
import { useHomePageContext } from '../../../context/HomePageProvider'

const useStyles = makeStyles(() => ({
  tablePaper: {
    fontFamily: 'Nunito',
    marginTop: 20,
    borderRadius: 20,
    paddingLeft: 10,
    paddingRight: 20,
    paddingBottom: 20,
    overflow: 'hidden',
    '& .MuiPaper-elevation2': {
      overflow: 'hidden',
      boxShadow: 'unset',
    },
    '& .MuiToolbar-gutters': {
      minHeight: 50,
      marginLeft: 10,
      marginTop: 10,
    },
  },
}))

const CTBTable = ({ parentPart }) => {
  const classes = useStyles()
  const ctbTableCellFontSize = 12
  const [includeDeliveries, setIncludeDeliveries] = useState(true)
  // const [includeBacklog, setIncludeBacklog] = useState(false)

  const { weeksOut } = useHomePageContext()
  const [ctbData, setCtbData] = useState({ data: [], columns: [] })
  const [popupAnchorEl, setPopupAnchorEl] = useState(null)
  const [partNumber, setPartNumber] = useState(null)

  function partNumberClicked(event, partNumber) {
    setPartNumber(partNumber)
    setPopupAnchorEl(event.currentTarget)
  }
  const formatColumns = (inputColumns) => {
    if (!inputColumns || !inputColumns.length) return []
    const outputColumns = [
      {
        title: 'PN',
        field: 'pn',
        headerStyle: {
          textAlign: 'center',
          width: 150,
          padding: 8,
        },
        cellStyle: {
          width: 150,
          //paddingLeft: 2,
          whiteSpace: 'nowrap',
        },
        render: (rowData) => {
          const styles = { cursor: 'pointer', textDecoration: 'underline' }
          return (
            <div
              className='text-hover'
              onClick={(event) => {
                partNumberClicked(event, rowData.pn)
              }}
            >
              <span style={styles}>{rowData.pn}</span>
            </div>
          )
        },
      },
      {
        title: 'Desc',
        field: 'desc',
        cellStyle: {
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: 135,
        },
      },
    ]

    for (let i = 2; i < inputColumns.length; i++) {
      let date = inputColumns[i]
      outputColumns.push({
        title: `${date.substring(0, 5)}`,
        field: date,
        cellStyle: { fontFamily: 'Nunito' },
        sorting: false,
        headerStyle: { width: 15, padding: 8 },
        render: function ctbDaily(rowData) {
          const cellData = rowData[date]
          const outerStyle = {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'center',
            justifyContent: 'center',
            width: 15,
            padding: 0,
            fontFamily: 'Nunito',
          }
          const innerStyle = {
            padding: 0,
            borderRadius: 5,
            fontFamily: 'Nunito',
            fontSize: 12,
          }
          if (cellData[0] < 0) innerStyle.color = '#cf3232'
          if (cellData[1]) {
            innerStyle.backgroundColor = '#b1eab3'
          }
          return (
            <div className='outerCont' style={outerStyle}>
              <Typography varient='body1' style={innerStyle}>
                {cellData[0]}
              </Typography>
            </div>
          )
        },
      })
    }
    return outputColumns
  }
  useCTBTable(parentPart, includeDeliveries, weeksOut.val, setCtbData, formatColumns)
  const { data, columns } = ctbData

  return (
    <Paper className={clsx('mat-table-cont', classes.tablePaper)}>
      <MaterialTableCustom
        components={{
          // eslint-disable-next-line react/display-name
          Toolbar: (props) => <CTBTableToolBar includeDeliveries={includeDeliveries} setIncludeDeliveries={setIncludeDeliveries} {...props} />,
        }}
        title='Clear To Build'
        columns={columns}
        data={data}
        rowStyle={{
          fontSize: `${ctbTableCellFontSize}px`,
          textAlign: 'center',
        }}
        options={{
          filtering: false,
          search: true,
          paging: false,
          maxBodyHeight: '700px',
          padding: '0',
        }}
      />
      <PartPopup partNumber={partNumber} setAnchorEl={setPopupAnchorEl} anchorEl={popupAnchorEl} />
    </Paper>
  )
}

CTBTable.propTypes = {
  parentPart: PropTypes.object,
}

export default CTBTable
