import React, { useState, useEffect } from 'react'
import DeliveriesHomePage from './sections/DeliveriesHomePage'
import HomeTopRow from './sections/HomePageTopRow'
import CTBTable from './sections/CTBTable'
import ProductionForecast from './sections/ProductionForecast'
import Container from '@material-ui/core/Container'
import { useParentParts } from '../../hooks/queries/useParentParts'
import GenericLoading from '../../components/GenericLoading'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { HomePageProvider } from '../../context/HomePageProvider'
import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-react'
import { useQueryClient } from 'react-query'

const useStyles = makeStyles(() => ({
  outerContainer: {
    padding: 20,
    paddingTop: 40,
  },
}))
const HomePage = () => {
  const classes = useStyles()

  const [parentPart, setParentPart] = useState();
  const queryClient = useQueryClient()
  const [parentPartList, setParentPartList] = useState([])
  const { user } = useAuth0();
  const ppQuery = useParentParts(setParentPart, setParentPartList, user?.email);

  useEffect(() => {
    if (user)
      axios.defaults.headers.common['Authorization'] = user?.email;
  }, [user]);

  if (ppQuery.isLoading) {
    return (
      <Grid container direction='row' spacing={1} alignItems='center' justifyContent='center'>
        <Grid item>
          <GenericLoading />
        </Grid>
      </Grid>
    )
  }

  const refreshDeliveryTableData = () => {
    queryClient.refetchQueries(["deliveryTable"]);
    queryClient.refetchQueries(["allDeliveries"]);
    queryClient.refetchQueries(["recentDeliveries"]);
  }

  return (
    <Container maxWidth='md' className={classes.outerContainer}>
      {ppQuery.isFetching ? null : <HomeTopRow setParentPart={setParentPart} parentParts={parentPartList} parentPart={parentPart} />}
      <ProductionForecast parentPart={parentPart} />
      <CTBTable parentPart={parentPart} />
      <DeliveriesHomePage parentPart={parentPart} heading='Recent and Upcoming Deliveries' refreshTableData={refreshDeliveryTableData} />
    </Container>
  )
}

const HomePageWithProvider = () => (
  <HomePageProvider>
    <HomePage />
  </HomePageProvider>
)

export default HomePageWithProvider
