import React, { useState } from 'react'
import { useDeliveriesSourceSummary } from '../../hooks/queries/sourceSummary'
import SourceAccordians from './components/SourceAccordian/SourceAccordians'
import { useAllDeliveries } from '../../hooks/queries/allDeliveries'
import DeliveriesSourceData from './sections/DeliveriesSourceData'
import AllDeliveriesTable from '../sourceData/components/AllDeliveriesTable'
import { useQueryClient } from 'react-query'
import Loading from '../../components/Loading'
import { useAuth0 } from '@auth0/auth0-react'

export default function DeliveriesPage() {
  const [sheetData, setSheetData] = useState({})
  const [expanded, setExpanded] = useState(false);

  const sourceSummaryType = 'deliverySummary'
  const sourceSummaryQueryKey = 'deliverySourceSummary'
  const dataSummaryQueryKey = 'deliveryTable'
  const pageDataType = 'deliveries'

  const { user } = useAuth0();

  const { isFetching: iFSourceData } = useDeliveriesSourceSummary(sourceSummaryQueryKey, sourceSummaryType, {
    setSheetData,
  })

  const {
    isLoading: iLTableData,
    isErroxr: iETableData,
    isFetching: iFTableData,
    data: deliveryTableData,
  } = useAllDeliveries(dataSummaryQueryKey, 1000, 0, user?.email)

  const queryClient = useQueryClient()
  const refetchData = () => {
    queryClient.refetchQueries([sourceSummaryQueryKey])
    queryClient.refetchQueries([dataSummaryQueryKey])
  }

  const refreshTableData = () => {
    queryClient.refetchQueries([dataSummaryQueryKey])
  }

  const setExpand = (val) => {
    setExpanded(val);
  }

  const sourceDataSources = iFSourceData ? [] : [<DeliveriesSourceData refetchData={refetchData} uploadSummary={sheetData} key={0} setExpand={setExpand} />]

  const clearSheetData = () => {
    setSheetData({})
  }

  const tableData = iLTableData || iETableData || iFTableData ? [] : deliveryTableData

  return (
    <>
      {iFSourceData ? (
        <Loading />
      ) : (
        <SourceAccordians
          label={'Delivery'}
          sources={sourceDataSources}
          refetchData={refetchData}
          clearSheetData={clearSheetData}
          displayComp={<AllDeliveriesTable data={tableData} refreshTableData={refreshTableData} />}
          sourceSummaryQueryKey={sourceSummaryQueryKey}
          dataSummaryQueryKey={dataSummaryQueryKey}
          pageDataType={pageDataType}
          toExpand={expanded}
        />
      )}
    </>
  )
}
