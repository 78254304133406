import axios from 'axios'
import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'

import { secondaryFormatter } from './utils'
import UploadSection from './UploadSection'
import LastUploadedSection from '../components/LastUploadedSection'
import RegularColumnSelection from '../components/RegularColumnSelection'

import { hasAllRequiredColumnValues } from './SourceDataMethods'
import { makeStyles } from '@material-ui/core/styles'
import { API_URL } from '../../../config'
import { firstLetterUpper } from '../../../util/stringTransform'
import toast from 'react-hot-toast'
import { logError } from '../../../util/logger'

const useStyles = makeStyles(() => ({
  breakLine: {
    margin: 10,
    marginTop: 20,
    marginBottom: 20,
  },
  root: {},
}))

const DeliveriesSourceData = ({ uploadSummary, refetchData, setExpand }) => {
  const classes = useStyles()

  const currentColumnRef = uploadSummary?.columnRef
  const currentSheetSelected = uploadSummary?.sheetNameToSelect
  const currentUploadedFile = uploadSummary?.fileName
  const lastImported = uploadSummary?.lastImported
  const fileStructure = uploadSummary?.fileStructure
  const fileToken = uploadSummary?.fileToken
  const status = uploadSummary?.status
  const statusMessage = uploadSummary?.statusMessage

  const [sheet, setSheet] = useState(currentSheetSelected || '')
  const [currentColumnList, setCurrentColumnList] = useState(currentSheetSelected ? secondaryFormatter(fileStructure, currentSheetSelected) : [])
  const [partNumber, setPartNumber] = useState(currentColumnRef?.partNumber || '')
  const [dateOfRecord, setDateOfRecord] = useState(currentColumnRef?.dateOfRecord || '')
  const [quantity, setQuantity] = useState(currentColumnRef?.quantity || '')
  const [deliveryReceivedStatus, setDeliveryReceivedStatus] = useState(currentColumnRef?.receivedStatus || '')
  const [deliveryDesc, setDeliveryDesc] = useState(currentColumnRef?.description || '')
  const [partType, setPartType] = useState(currentColumnRef?.partType || '')
  const [vendorName, setVendorName] = useState(currentColumnRef?.vendorName || '')
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState(currentColumnRef?.purchaseOrderNumber || '')
  const [trackingNumber, setTrackingNumber] = useState(currentColumnRef?.trackingNumber || '')
  const [disableSave, setDisableSave] = useState(true)

  const setColumnData = (inputSheet, sheet) => {
    setCurrentColumnList(secondaryFormatter(inputSheet.fileStructure, sheet))
  }

  const changeSheet = (sheetName) => {
    if (sheetName === '') {
      setCurrentColumnList([])
    } else setColumnData(uploadSummary, sheetName)
    setSheet(sheetName)
  }

  const criticalColumnConfig = [
    {
      label: 'Part Number',
      ref: partNumber,
      refSet: setPartNumber,
      required: true,
    },
    {
      label: 'Delivery Date',
      ref: dateOfRecord,
      refSet: setDateOfRecord,
      required: true,
    },
    {
      label: 'Estimated Quantity',
      ref: quantity,
      refSet: setQuantity,
      required: true,
    },
    {
      label: 'Supplier Name',
      ref: vendorName,
      refSet: setVendorName,
      required: false,
    },
    {
      label: 'PO Number',
      ref: purchaseOrderNumber,
      refSet: setPurchaseOrderNumber,
      required: false,
    },
    {
      label: 'Tracking Number',
      ref: trackingNumber,
      refSet: setTrackingNumber,
      required: false,
    },
  ]

  const allRequiredFieldsSelected = hasAllRequiredColumnValues(criticalColumnConfig)
  const handleFieldChange = (setStateFn, value) => {
    setStateFn(value)
    if (disableSave) setDisableSave(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const setDeliveryColumnRef = `${API_URL}/api/dataSource/v1/setDeliveryColumnRef`
    const data = {
      fileToken: fileToken,
      cleanImport: true,
      sheetName: sheet,
      columnRef: {
        partNumber,
        dateOfRecord,
        quantity,
        receivedStatus: deliveryReceivedStatus,
        deliveryDesc,
        partType,
        vendorName,
        purchaseOrderNumber,
        trackingNumber,
      },
    }

    const updateDeliveriesColumnRef = axios
      .post(setDeliveryColumnRef, data)
      .then(async () => {
        await refetchData()
        setDisableSave(true)
        setExpand(false);
      })
      .catch((err) => {
        toast.error('Somethign went wrong while trying to save your new selection. Please try again')
        logError(err.message, err.stack)
      })

    toast.promise(updateDeliveriesColumnRef, {
      loading: <div>{`New Deliveries! This will replace your old deliveries data.`}</div>,
      success: <b>Updated!</b>,
      error: <b>We had an issue saving your changes, please try again!</b>,
    })
  }

  return (
    <Grid container spacing={2} direction='column' className={classes.root}>
      <UploadSection uploadDataType='deliveries' refetchSourceInfoFn={refetchData} setExpand={setExpand} />
      <hr className={classes.breakLine} />
      <LastUploadedSection
        fileName={currentUploadedFile}
        lastImported={lastImported}
        status={firstLetterUpper(status)}
        statusMessage={statusMessage}
        label={`Deliveries`}
      />
      <hr className={classes.breakLine} />
      <RegularColumnSelection
        config={criticalColumnConfig}
        changeSheet={changeSheet}
        currentSheet={sheet}
        fileStructure={fileStructure}
        currentColumnList={currentColumnList}
        handleFieldChange={handleFieldChange}
        handleSubmit={handleSubmit}
        allRequiredFieldsSelected={allRequiredFieldsSelected}
        disableSave={disableSave}
      />
    </Grid>
  )
}

export default DeliveriesSourceData
