import { useQuery } from 'react-query'
import axios from 'axios'
import { API_URL } from '../../config'
import { rqDefaultOptions } from './defaultOptions'
import { authStorage } from '../../context/Auth0ProviderWithHistory'

const getRecentDeliveries = async (inputObj) => {
  const { parentPart } = inputObj.queryKey[1]
  const recentDeliveriesAPIPath = `${API_URL}/api/delivery/v1/recentDeliveries?parentPart=${parentPart.partNumber}`

  const res = await axios.get(recentDeliveriesAPIPath)

  return res.data
}

export function useRecentDeliveries(parentPart) {
  const options = {
    enabled: !!parentPart,
    ...rqDefaultOptions,
  }
  return useQuery(['recentDeliveries', { parentPart }], getRecentDeliveries, options)
}
