import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/DeleteOutlineRounded'
import Button from '@material-ui/core/Button'

import { useDeleteSource } from '../../../../hooks/queries/'

const useStyles = makeStyles(() => ({
  heading: {
    fontSize: '1.2rem',
    fontWeight: 500,
  },
  accordianRoot: {
    borderRadius: 15,
    overflow: 'hidden',
    padding: 3,
    margin: '0px 0px 10px 0px',
  },
  accDeets: {
    padding: 20,
    paddingTop: 10,
    paddingLeft: 40,
  },
  deleteSourceButton: {
    borderRadius: 10,
    width: 30,
    padding: 0,
    '& span': {
      padding: 0,
      margin: 0,
    },
    '& .MuiButton-label': {
      width: 30,
    },
  },
}))

export default function SourceAccordianMain({ toExpand=false, sourceComp, clearSheetData, refetchData, label}) {
  const classes = useStyles()

  const [expanded, setExpanded] = React.useState<boolean>(toExpand)

  const fileToken = sourceComp?.props?.uploadSummary?.fileToken
  const dataType = sourceComp?.props?.uploadSummary?.dataType

  const { mutateAsync: deleteSource } = useDeleteSource()

  const deleteSourceCallBack = async (fileToken, dataType) => {
    await deleteSource({
      dataType,
      fileToken,
    })
    clearSheetData()
    refetchData()
  }

  return (
    <Accordion expanded={expanded} className={classes.accordianRoot} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
        <Grid container spacing={1} direction='row' justifyContent='space-between'>
          <Typography className={classes.heading}>
            {sourceComp?.props?.label ? `${label} - ${sourceComp?.props?.label} Source Data` : `${label} Source Data`}
          </Typography>
          {fileToken && (
            <Button className={classes.deleteSourceButton} startIcon={<DeleteIcon />} onClick={() => deleteSourceCallBack(fileToken, dataType)} />
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accDeets}>{sourceComp}</AccordionDetails>
    </Accordion>
  )
}
