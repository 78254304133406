import 'react'
import { useMutation } from 'react-query'
import axios from 'axios'
import { API_URL } from '../../../config'

export const deleteSource = async (sourceDeletionObj) => {
  const { dataType, fileToken } = sourceDeletionObj
  const deleteSourceAPIPath = `${API_URL}/api/dataSource/v1/${dataType}/${fileToken}`
  const res = await axios.delete(deleteSourceAPIPath)
  return res.data
}

export function useDeleteSource() {
  return useMutation(deleteSource)
}
