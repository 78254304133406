import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  loading: {
    height: '98vh',
  },
}))
export default function Loading() {
  const classes = useStyles()

  return (
    <Grid container direction='column' justifyContent='center' alignItems='center'>
      <Grid container direction='row' justifyContent='center' alignItems='center' className={classes.loading}>
        <CircularProgress size={100} />
      </Grid>
    </Grid>
  )
}
