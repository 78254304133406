import { useQuery } from 'react-query'
import axios from 'axios'
import { API_URL } from '../../config'
import { rqDefaultOptions } from './defaultOptions'

const getActualsData = async (inputObj) => {
  const { limit, skip } = inputObj.queryKey[1]
  const allDeliveriesAPIPath = `${API_URL}/api/actuals/v1/getActualsData?limit=${limit}&skip=${skip}`
  const res = await axios.get(allDeliveriesAPIPath)
  return res.data
}

export function useActualsData(queryKey, limit = 20, skip = 0) {
  const options = {
    ...rqDefaultOptions,
  }
  return useQuery([queryKey, { limit, skip }], getActualsData, options)
}
