import { useQuery } from 'react-query'
import axios from 'axios'
import { API_URL } from '../../../config'
import { rqDefaultOptions } from '../defaultOptions'

const getCTBSummaryStats = async (inputObj) => {
  const { parentPart, weeksOut } = inputObj.queryKey[1]
  const getSummaryStatsAPI = `${API_URL}/api/ctb/v1/summaryStats?parentPart=${parentPart?.partNumber ?? ''}&weeksOut=${weeksOut}`
  const res = await axios.get(getSummaryStatsAPI)
  return res.data
}

export function useCTBSummaryStats(queryKey, parentPart, weeksOut) {
  const options = {
    ...rqDefaultOptions,
  }
  return useQuery([queryKey, { parentPart, weeksOut }], getCTBSummaryStats, options)
}
