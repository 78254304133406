import axios from 'axios'
import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Tooltip } from '@material-ui/core'
import BackupIcon from '@material-ui/icons/Backup'
import RoundedButton from '../../../components/RoundedButton'
import { API_URL } from '../../../config'
import toast from 'react-hot-toast'
import { logError } from '../../../util/logger'

const useStyles = makeStyles((theme) => ({
  inputStyles: {
    borderRadius: 15,
    '& MuiInputBase-input': {
      borderRadius: 15,
    },
    // overflow: 'hidden',
  },
  InputCont: {
    // flexGrow: 2,
  },
  root: {
    maxWidth: 700,
  },
  input: {
    display: 'none',
  },
  uploadRow: {
    paddingBottom: 10,
    paddingTop: 10,
  },
  uploadImage: {
    color: theme.palette.primary.light,
    borderRadius: 15,
  },
  uploadLine: {
    marginLeft: 10,
  },
  uploadText: {
    marginLeft: 10,
    fontSize: '0.7rem',
  },
}))
const UploadSection = ({ uploadDataType, refetchSourceInfoFn, setExpand }) => {
  const [googleSheet, setGoogleSheet] = useState('')
  const [file, setFile] = useState()
  const [fileName, setFileName] = useState()
  const [disabledState, setDisabledState] = useState('upload')
  const [isUploading, setIsUploading] = useState(false)

  const classes = useStyles()

  const handleChange = (event) => {
    setDisabledState(event.target.value)
  }

  const handleFileSet = (e) => {
    setFileName(e.target?.files[0]?.name)
    setFile(e.target.files[0])
  }

  const handleFileUpload = async (type) => {
    if (type === 'file') {
      const uploadAPIPath = `${API_URL}/api/dataSource/v2/uploadFile`
      setIsUploading(true)
      let formData = new FormData()
      formData.append('uploadFile', file)
      formData.append('dataType', uploadDataType)
      axios
        .post(uploadAPIPath, formData)
        .then(() => {
          setFile(null)
          setFileName('')
          refetchSourceInfoFn()
          setIsUploading(false)
          setExpand(true);
        })
        .catch((err) => {
          toast.error('Oops! Something was odd with your file upload. Please try again', { duration: 10000, style: { minWidth: 500 } })
          setIsUploading(false)
          setExpand(false);
          logError(err.message, err.stack)
        })
    } else {
      const googlesheetUploadAPIPath = `${API_URL}/api/dataSource/v1/addGoogleSheet`
      setIsUploading(true)
      const data = {
        googleSheetURL: googleSheet,
        dataType: uploadDataType,
      }
      axios
        .post(googlesheetUploadAPIPath, data)
        .then(() => {
          setGoogleSheet('')
          refetchSourceInfoFn()
          setIsUploading(false)
        })
        .catch((err) => {
          toast.error(
            'Oops! Something was odd with the provided Google Sheets Link. Please provide a valid Google Sheet link and ensure we have permission to read it.',
            { duration: 10000, style: { minWidth: 500 } },
          )
          setIsUploading(false)
          logError(err.message, err.stack)
        })
        // console.log(setExpand);
        setExpand(true);
    }
  }

  const randomRefNumber = Math.random().toString(36).substring(7)

  return (
    <form className={classes.root}>
      <RadioGroup value={disabledState} onChange={handleChange}>
        <Grid className={classes.uploadRow} container spacing={2} justifyContent='center' alignItems='center' direction='column'>
          <Grid item container direction='row' justifyContent='space-between'>
            <Grid item>
              <FormControlLabel value='upload' control={<Radio />} label='Upload Excel or CSV file' />
            </Grid>
            <Grid item>
              <input
                className={classes.input}
                type='file'
                accept='.csv,.xlsx'
                name='file'
                id={`fileUpload-${randomRefNumber}`}
                onChange={(e) => handleFileSet(e)}
              />
              <Tooltip title='Upload File'>
                <label htmlFor={`fileUpload-${randomRefNumber}`} style={{ marginBottom: 0 }}>
                  <Grid container direction='row' alignItems='center' justifyContent='center'>
                    <Button
                      className={classes.uploadImage}
                      disabled={disabledState !== 'upload'}
                      color='primary'
                      aria-label='upload picture'
                      component='span'
                    >
                      <BackupIcon fontSize='medium' />
                      <Typography className={classes.uploadText}>{fileName || 'Select File To Upload'}</Typography>
                    </Button>
                  </Grid>
                </label>
              </Tooltip>
            </Grid>
            <Grid item>
              <RoundedButton
                onClick={() => handleFileUpload('file')}
                variant='contained'
                color='secondary'
                isLoading={disabledState === 'upload' && isUploading}
                disabled={!fileName || disabledState !== 'upload'}
              >
                Upload
              </RoundedButton>
            </Grid>
          </Grid>
          <Grid item container spacing={2} justifyContent='space-between' alignItems='center' direction='row'>
            <Grid item>
              <FormControlLabel value='import' control={<Radio />} label='Import from Google Sheets' />
            </Grid>
            <Grid item className={classes.InputCont}>
              <TextField
                disabled={disabledState !== 'import'}
                label='Sheets URL'
                className={classes.inputStyles}
                placeholder='Link to sheet'
                variant={disabledState !== 'import' ? 'filled' : 'outlined'}
                value={googleSheet}
                size='small'
                onChange={(e) => setGoogleSheet(e.target.value)}
              />
            </Grid>
            <Grid item>
              <RoundedButton
                as='label'
                disabled={disabledState !== 'import' || !googleSheet}
                variant='contained'
                color='secondary'
                isLoading={disabledState === 'import' && isUploading}
                onClick={() => handleFileUpload('link')}
              >
                Connect
              </RoundedButton>
            </Grid>
          </Grid>
        </Grid>
      </RadioGroup>
    </form>
  )
}

export default UploadSection
