export const ENV = process.env.REACT_APP_ENV
let API_URL
if (!ENV || ENV === 'development') API_URL = process.env.REACT_APP_API_URL
else if (ENV === 'local') API_URL = process.env.REACT_APP_API_URL_LOCAL
else API_URL = process.env.REACT_APP_API_URL

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID
const SKIP_AUTH = process.env.REACT_APP_SKIP_AUTH

export { API_URL, AUTH0_DOMAIN, AUTH0_CLIENT_ID, SKIP_AUTH }
