import './partExclusionStyles.scss'
import React from 'react'
import PartExclusionInput from './PartExclusion'
import PartExclusionOnlyChildren from './PartExclusionOnlyChildren'
import Grid from '@material-ui/core/Grid'
import { useGetPartExclusion } from '../../../hooks/queries/exclusions'
import { useQueryClient } from 'react-query'

export default function Exclusions({ currentParts }) {
  const getPartExclusionsAPIKey = 'getPartExclusions'
  const queryClient = useQueryClient()

  const refetchData = () => {
    queryClient.refetchQueries([getPartExclusionsAPIKey])
  }

  const {
    isLoading: iLGetPartExc,
    // isError: iEGetPartExc,
    // isFetching: IFGetPartExc,
    data: dGetPartExc,
  } = useGetPartExclusion(getPartExclusionsAPIKey)

  const excludedParts = iLGetPartExc ? [] : dGetPartExc.excludedParts
  const excludedChildrenOfParts = iLGetPartExc ? [] : dGetPartExc.excludedChildrenOfParts

  return (
    <Grid container spacing={3} direction='column' justifyContent='flex-start' className='exclusion-cont'>
      <Grid item>
        <PartExclusionInput dataArray={excludedParts.filter(e => currentParts?.some(p => p.partNumber == e))} refetchData={refetchData} />
      </Grid>
      <Grid item>
        <PartExclusionOnlyChildren dataArray={excludedChildrenOfParts.filter(e => currentParts?.some(p => p.partNumber == e))} refetchData={refetchData} />
      </Grid>
    </Grid>
  )
}
