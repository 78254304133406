import React, { createContext, useState, useContext, Dispatch, SetStateAction } from 'react'

const HomePageContext = createContext<{
  weeksOut: {
    val: number
    set: Dispatch<SetStateAction<number>>
  }
  parentPart: {
    val: string
    set: Dispatch<SetStateAction<string>>
  }
}>(undefined)

interface HomePageProviderProps {
  children: React.ReactChild
}
export function HomePageProvider({ children }: HomePageProviderProps) {
  const [wOut, setWeeksOut] = useState(20)
  const [pPart, setParentPart] = useState('')

  const contextObj = {
    weeksOut: {
      val: wOut,
      set: setWeeksOut,
    },
    parentPart: {
      val: pPart,
      set: setParentPart,
    },
  }

  return <HomePageContext.Provider value={contextObj}>{children}</HomePageContext.Provider>
}

export function useHomePageContext() {
  const context = useContext(HomePageContext)
  if (context === undefined) {
    throw new Error('useHomePageContext must be used within a HomePageProvider Component')
  }
  return context
}
