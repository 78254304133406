import React from 'react'
import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fade from '@material-ui/core/Fade'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  root: {
    borderRadius: 10,
    paddingLeft: 10,
    paddingRight: 10,
    width: 100,
  },
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function RoundedButton(props: { [x: string]: unknown; classes: any; children: React.ReactChild; className: string; isLoading: boolean }) {
  const { classes, children, className, isLoading, ...other } = props

  return (
    <Grid container spacing={2} alignItems='center' justifyContent='center'>
      {isLoading !== undefined ? (
        <Fade
          in={isLoading}
          style={{
            transitionDelay: isLoading ? '400ms' : '0ms',
            marginRight: 10,
          }}
          unmountOnExit
        >
          <CircularProgress />
        </Fade>
      ) : (
        <div style={{ height: 30, width: 30 }} />
      )}
      <Button className={clsx(className, classes.root)} {...other}>
        {children || 'class names'}
      </Button>
    </Grid>
  )
}

export default withStyles(styles)(RoundedButton)
