import React from 'react'
import { Container } from '@material-ui/core'
import OverallSourceSumary from './sections/OverallSourceSumary'

const SourceDataPage = () => {
  return (
    <section className='bg-light py-5'>
      <Container>
        <OverallSourceSumary />
      </Container>
    </section>
  )
}

export default SourceDataPage
