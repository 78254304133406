import React, { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Grid from '@material-ui/core/Grid'
import GenericLoading from '../../components/GenericLoading'
import { Container, Paper, Typography } from '@material-ui/core'
import { SingleForecast } from './components/SingleForecast'
import { useForecastDataSources } from '../../hooks/queries/useForecastDataSources';
import ProductSelection from '../../components/ProductSelection'

export const ForecastPage = () => {
  const [forecastDataSource, setForecastDataSource] = useState();
  const [forecastDataSourceList, setForecastDataSourcesList] = useState([])
  const { user } = useAuth0();
  const ppQuery = useForecastDataSources(setForecastDataSource, setForecastDataSourcesList, user?.email);

  function resetProductList() {
    setForecastDataSource(undefined);
    ppQuery.refetch();
  }

  if (!ppQuery.isFetched) {
    return (
      <Grid container direction='row' spacing={1} alignItems='center' justifyContent='center'>
        <Grid item>
          <GenericLoading />
        </Grid>
      </Grid>
    )
  }

  return <>
    <Container>
      <Paper style={{ marginTop: 20, padding: 16, borderRadius: 15, display: 'flex', alignItems: 'center' }}>
        <Typography style={{width: '100%', fontSize: '1.2rem', fontWeight: 500,}}>Select Forecast:</Typography>
        <ProductSelection
          options={forecastDataSourceList}
          newOptionDescription='Add New Forecast'
          setOption={setForecastDataSource}
          currentOption={forecastDataSource}
        />
      </Paper>
    </Container>
    <SingleForecast forecast={forecastDataSource} resetProductList={resetProductList} />
  </>
}
