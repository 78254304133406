import Bill from './bill.svg'
import Inventory from './inventory.svg'
import Deliveries from './deliveries.svg'
import Forecast from './forecast.svg'
import Actual from './actuals.svg'
import Box from './filebox.svg'
import Dots from './dots.svg'
// import Delete from './delete.png'
import HomePageIcon3 from './homepageicon1.svg'
import HomePageIcon4 from './hp4.svg'

const Icons = {
  Dots,
  Box,
  Bill,
  Deliveries,
  Actual,
  Inventory,
  Forecast,
  // Delete,
  HomePageIcon3,
  HomePageIcon4,
}

export default Icons
