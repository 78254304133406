export const hasAllRequiredColumnValues = (config) => {
  const requiredVars = config.filter(({ required = false }) => required).map(({ ref }) => ref)
  let out = true
  for (const item of requiredVars) {
    if (!item) {
      out = false
      break
    }
  }
  return out
}
