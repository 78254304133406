import React, { useState } from 'react'
import axios from 'axios'

import UploadSection from './UploadSection'
import LastUploadedSection from '../components/LastUploadedSection'
import { makeStyles } from '@material-ui/core/styles'
import ExtraColumnSelection from '../components/ExtraColumnSelection'
import ExtraConfigInventory from '../components/ExtraConfigInventory'
import Grid from '@material-ui/core/Grid'

import { secondaryFormatter } from './utils'
import { hasAllRequiredColumnValues } from './SourceDataMethods'
import { API_URL } from '../../../config'
import { firstLetterUpper } from '../../../util/stringTransform'
import toast from 'react-hot-toast'
import { logError } from '../../../util/logger'

const useStyles = makeStyles(() => ({
  breakLine: {
    margin: 10,
  },
}))

const InventorySourceData = ({ uploadSummary, refetchData, setExpand }) => {
  const classes = useStyles()
  const currentColumnRef = uploadSummary?.columnRef
  const currentSheetSelected = uploadSummary?.sheetNameToSelect
  const currStockName = uploadSummary?.stockName
  const currentUploadedFile = uploadSummary?.fileName
  const lastImported = uploadSummary?.lastImported
  const isAggregated = uploadSummary?.aggregateSumQty
  const fileStructure = uploadSummary?.fileStructure
  const fileToken = uploadSummary?.fileToken
  const status = uploadSummary?.status
  const statusMessage = uploadSummary?.statusMessage
  const partMapT = uploadSummary?.partMap

  const [aggregateSumQty, setAggregateSumQty] = useState(isAggregated || false)
  const [clearOtherInventory, setClearOtherInventory] = useState(false)
  const [currentColumnList, setCurrentColumnList] = useState(currentSheetSelected ? secondaryFormatter(fileStructure, currentSheetSelected) : [])

  const [sheet, setSheet] = useState(currentSheetSelected || '')
  const [partNumber, setPartNumber] = useState(currentColumnRef?.partNumber || '')
  const [regularStock, setRegularStock] = useState(currentColumnRef?.regularStock || '')
  const [inQuality, setInQuality] = useState(currentColumnRef?.inQuality || '')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totalQty, setTotalQty] = useState(currentColumnRef?.totalQty || '')
  const [stockName, setStockName] = useState(currStockName || '')

  const [disableSave, setDisableSave] = useState(true)
  const [aliasPartMapToken, setAliasPartMapToken] = useState(partMapT?.fileToken || '')
  const [aliasPartMapFileName, setAliasPartMapFileName] = useState(partMapT?.fileName || '')

  const setColumnData = (inputSheet, sheet) => {
    setCurrentColumnList(secondaryFormatter(inputSheet.fileStructure, sheet))
  }

  const changeSheet = (sheetName) => {
    if (sheetName === '') {
      setCurrentColumnList([])
    } else setColumnData(uploadSummary, sheetName)
    setSheet(sheetName)
  }

  const criticalColumnConfig = [
    {
      label: 'Part Number',
      ref: partNumber,
      refSet: setPartNumber,
      required: true,
    },
    {
      label: 'Available Inventory Qty',
      ref: regularStock,
      refSet: setRegularStock,
      required: true,
    },
    {
      label: 'Quality Hold Inventory Qty',
      ref: inQuality,
      refSet: setInQuality,
      required: false,
    },
    // {
    //   label: 'Total Qty Column',
    //   ref: totalQty,
    //   refSet: setTotalQty,
    //   required: false,
    // },
  ]

  const allRequiredFieldsSelected = hasAllRequiredColumnValues([{ ref: stockName, required: true }, ...criticalColumnConfig])

  const handleFieldChange = (setStateFn, value) => {
    setStateFn(value)
    if (disableSave) setDisableSave(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const setInventoryColumnRef = `${API_URL}/api/dataSource/v2/setInventoryColumnRef`
    const data = {
      fileToken: fileToken,
      stockName: stockName,
      aggregateSumQty: aggregateSumQty,
      clearAllStockQtys: false,
      sheetName: sheet,
      columnRef: {
        partNumber: partNumber,
        regularStock: regularStock,
        inQuality: inQuality,
        totalQty: totalQty,
      },
      ...(aliasPartMapToken && { partMapAliasToken: aliasPartMapToken }),
    }

    const updateInventoryColumnRef = axios
      .post(setInventoryColumnRef, data)
      .then(async () => {
        await refetchData()
        setDisableSave(true)
      })
      .catch((err) => {
        toast.error('Somethign went wrong while trying to save your new selection. Please try again')
        logError(err.message, err.stack)
      })

    toast.promise(updateInventoryColumnRef, {
      loading: <div>{`Adding your new inventory...`}</div>,
      success: <b>Updated!</b>,
      error: <b>We had an issue saving your changes, please try again!</b>,
    })

    setExpand(false)
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={8}>
        <UploadSection uploadDataType='inventory' refetchSourceInfoFn={refetchData} setExpand={setExpand} />
        <hr className={classes.breakLine} />
        <LastUploadedSection
          fileName={currentUploadedFile}
          lastImported={lastImported}
          status={firstLetterUpper(status)}
          statusMessage={statusMessage}
          label={`Inventory`}
        />
        <hr className={classes.breakLine} />
        <ExtraColumnSelection
          config={criticalColumnConfig}
          changeSheet={changeSheet}
          currentSheet={sheet}
          fileStructure={fileStructure}
          currentColumnList={currentColumnList}
          handleFieldChange={handleFieldChange}
          handleSubmit={handleSubmit}
          allRequiredFieldsSelected={allRequiredFieldsSelected}
          disableSave={disableSave}
          leftSideComp={
            <ExtraConfigInventory
              handleFieldChange={handleFieldChange}
              stockName={stockName}
              setStockName={setStockName}
              aggregateSumQty={aggregateSumQty}
              setAggregateSumQty={setAggregateSumQty}
              clearOtherInventory={clearOtherInventory}
              setClearOtherInventory={setClearOtherInventory}
              aliasPartMapToken={aliasPartMapToken}
              setAliasPartMapToken={setAliasPartMapToken}
              aliasPartMapFileName={aliasPartMapFileName}
              setAliasPartMapFileName={setAliasPartMapFileName}
            />
          }
        />
      </Grid>
    </Grid>
  )
}

export default InventorySourceData
