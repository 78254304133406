import React from 'react'
import Select from '@material-ui/core/Select'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { SectionHeader } from '../../../components/Title'
import { Grid } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import ReactHomePageImage from '../../../assets/icons/ReactHomePageImage.svg'
import { useCTBSummaryStats } from '../../../hooks/queries/ctbSummary'
import { useHomePageContext } from '../../../context/HomePageProvider'
import GenericLoading from '../../../components/GenericLoading'
import ProductSelection from '../../../components/ProductSelection'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectGrid: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
  selectEmpty: {
    fontSize: 15,
    height: 40,
  },
  root: {
    paddingBottom: 30,
  },
  homePageImgCont: {
    padding: 0,
  },
  homePageImg: {
    width: 'inheret',
    height: 'inheret',
  },
  unitNumbers: {
    fontSize: 26,
  },
  boxTitles: {
    fontSize: 18,
  },
}))

const HomeTopRow = ({ setParentPart, parentParts, parentPart }) => {
  const classes = useStyles()
  const { weeksOut } = useHomePageContext()

  const ctbSummaryQuery = useCTBSummaryStats('CTBSummaryStats', parentPart, weeksOut.val)

  const { isLoading, isFetching, isError, data: summaryStats } = ctbSummaryQuery

  if (!parentParts.length)
    return (
      <Grid container direction='row' spacing={1} alignItems='center' justifyContent='center'>
        <Grid item>
          <Typography>
            Looks like we dont have enough information. Head to{' '}
            <span>
              <Link to='/source-data'>Source Data</Link>
            </span>{' '}
            to upload more information.
          </Typography>
        </Grid>
      </Grid>
    )

  if (isLoading)
    return (
      <Grid container direction='row' spacing={1} alignItems='center' justifyContent='center'>
        <Grid item>
          <GenericLoading />
        </Grid>
      </Grid>
    )

  return (
    <Grid container direction='row' className={classes.root}>
      <Grid item xs={12} sm={12} md={3} className={`py-xl-0 order-lg-first pr-3 ${classes.selectGrid}`}>
        <div className='bg-white customBox p-3 h-100'>
          <SectionHeader className={`mb-4 ${classes.boxTitles}`} heading={`Select Product:`} />
          <ProductSelection
            options={parentParts}
            setOption={(op) => setParentPart(op)}
            currentOption={parentPart}
          />
        </div>
      </Grid>
      <Grid xs={12} sm={3} md={2} className='pr-3'>
        <div className='d-flex flex-column bg-white customBox  h-100 text-center text-capitalize'>
          <Typography className={`pt-3 mb-0 ${classes.boxTitles}`}>Clear to Build</Typography>
          <div>
            <Typography className={`mb-0 font-weight-bold ${classes.unitNumbers}`}>{summaryStats.ctbUnitsWithoutDeliveries}</Typography>
            <div style={{ fontSize: '12px' }}>
              <Typography>Units</Typography>
            </div>
          </div>
          <div style={{ fontSize: '12px' }}>
            <Typography>With Inventory</Typography>
          </div>
        </div>
      </Grid>
      <Grid xs={12} sm={6} md={2} className='py-xl-0 pr-3'>
        <div className='d-flex flex-column bg-white customBox  h-100 text-center text-capitalize'>
          <Typography className={`pt-3 mb-0 ${classes.boxTitles}`}>Clear to Build</Typography>
          <div>
            <Typography className={`mb-0 font-weight-bold ${classes.unitNumbers}`}>{summaryStats.ctbUnitsWithDeliveries}</Typography>
            <div style={{ fontSize: '12px' }}>
              <Typography>Units</Typography>
            </div>
          </div>
          <div style={{ fontSize: '12px' }}>
            <Typography>Inc. Deliveries</Typography>
          </div>
        </div>
      </Grid>
      <Grid xs={12} sm={6} md={5} className={classes.homePageImgCont} item={true}>
        <img src={ReactHomePageImage} />
      </Grid>
    </Grid>
  )
}
HomeTopRow.propTypes = {
  setParentPart: PropTypes.func,
  parentParts: PropTypes.array,
  parentPart: PropTypes.object,
}

export default HomeTopRow
