import React from 'react'
import Grid from '@material-ui/core/Grid'
import { MTableToolbar } from '@material-table/core'
import { makeStyles } from '@material-ui/core/styles'
import { Checkbox, FormControlLabel } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      padding: 0,
      minHeight: 0,
    },
  },
  incDeliveries: {
    marginRight: 0,
    marginLeft: 0,

    '& span.MuiTypography-root': {
      fontSize: 12,
    },
    '& span.MuiIconButton-root': {
      paddingRight: 5,
    },
    '& svg.MuiSvgIcon-root': {
      fontSize: '1.2rem',
    },
  },
}))

export default function CTBTableToolBar(inputProps) {
  const { includeDeliveries, setIncludeDeliveries, ...props } = inputProps

  const classes = useStyles()

  return (
    <div className={classes.toolbarWrapper}>
      <MTableToolbar {...props} />
      <Grid container spacing={3} direction='row' justifyContent='center' alignItems='center'>
        <Grid item container direction='row' spacing={2} xs={4} justifyContent='flex-start' alignItems='center'>
          <Grid conatiner item direction='row' spacing={2} style={{ marginLeft: 9 }}>
            <span
              style={{
                display: 'inline-block',
                marginLeft: 0,
                marginRight: 5,
              }}
              className='legend-marker bg-danger'
            />
            <div
              style={{
                position: 'relative',
                display: 'inline-block',
                fontSize: 12,
              }}
            >
              {' '}
              Part Shortage
            </div>
          </Grid>
          <Grid item conatiner direction='row' spacing={2}>
            <span
              style={{
                display: 'inline-block',
                marginLeft: 3,
                marginRight: 5,
              }}
              className='legend-marker bg-success'
            />
            <div
              style={{
                position: 'relative',
                display: 'inline-block',
                fontSize: 12,
              }}
            >
              Part Delivery
            </div>
          </Grid>
        </Grid>
        <Grid item container spacing={2} xs={8} direction='row' justifyContent='flex-end'>
          <FormControlLabel
            className={classes.incDeliveries}
            control={
              <Checkbox
                onChange={() => setIncludeDeliveries(!includeDeliveries)}
                name='deliveriesCheckBox'
                color='secondary'
                checked={includeDeliveries}
              />
            }
            label='Include Deliveries'
          />
        </Grid>
      </Grid>
    </div>
  )
}
