import { useQuery } from 'react-query'
import axios from 'axios'
import { API_URL } from '../../../config'
import { rqDefaultOptions } from '../defaultOptions'

const getInventoryTable = async (inputObj) => {
  const { limit, skip } = inputObj.queryKey[1]
  const allDeliveriesAPIPath = `${API_URL}/api/inventory/v1/getInventoryData?limit=${limit}&skip=${skip}&QC=${true}`
  const res = await axios.get(allDeliveriesAPIPath)
  return res.data
}

export function useInventoryTable(queryKey, limit = 20, skip = 0) {
  const options = {
    ...rqDefaultOptions,
  }
  return useQuery([queryKey, { limit, skip }], getInventoryTable, options)
}
