import React from 'react'
import { Button, Container, Drawer, makeStyles } from '@material-ui/core'
import { NavLink, useLocation } from 'react-router-dom'
import clsx from 'clsx'

import MenuIcon from '@material-ui/icons/Menu'

const useStyles = makeStyles(() => ({
  header: {
    height: 48,
  },
}))

const NavbarSource = () => {
  const { pathname } = useLocation()
  const [drawer, setDrawer] = React.useState(false)
  const classes = useStyles()

  if (!pathname.includes('/source-data') || pathname === '/source-data') {
    return null
  }

  return (
    <>
      <header className={clsx('navbar-container-2 bg-1 position-sticky fixed-top', classes.header)} style={{ top: 48 }}>
        <Container>
          <section className='row align-items-baseline  mx-0'>
            <div className='d-block d-lg-none'>
              <Button onClick={() => setDrawer(true)}>
                <MenuIcon className='menu-icon' />
              </Button>
            </div>
            <ul className='nav justify-content-between px-0 col-lg-9 mx-auto d-none d-lg-flex'>
              <li className='nav-item'>
                <NavLink className='nav-link text-primary' activeClassName='text-white-2 ' exact to='/source-data/bill'>
                  BOM
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink className='nav-link text-primary' activeClassName='text-white-2 ' exact to='/source-data/inventory'>
                  Inventory
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink className='nav-link text-primary' activeClassName='text-white-2 ' exact to='/source-data/forecast'>
                  Forecast
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink className='nav-link text-primary' activeClassName='text-white-2 ' exact to='/source-data/deliveries'>
                  Deliveries
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink className='nav-link text-primary' activeClassName='text-white-2 ' exact to='/source-data/productionActual'>
                  Actuals
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink className='nav-link text-primary' activeClassName='text-white-2 ' exact to='/source-data'>
                  Back
                </NavLink>
              </li>
            </ul>
          </section>
        </Container>
      </header>
      <Drawer open={drawer} onClose={() => setDrawer(false)}>
        <ul className='nav flex-column p-4'>
          <li className='nav-item'>
            <NavLink className='nav-link' exact to='/source-data/bill'>
              Bom
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink className='nav-link' exact to='/source-data/inventory'>
              Inventory
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink className='nav-link' exact to='/source-data/forecast'>
              Forecast
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink className='nav-link' exact to='/source-data/deliveries'>
              Deliveries
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink className='nav-link' exact to='/source-data/productionActual'>
              Actuals
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink className='nav-link' exact to='/source-data'>
              Back
            </NavLink>
          </li>
        </ul>
      </Drawer>
    </>
  )
}

export default NavbarSource
