import { useQuery } from 'react-query'
import axios from 'axios'
import { API_URL } from '../../config'
import { rqDefaultOptions } from './defaultOptions'

const getSourceSummary = async (inputObj) => {
  const { sourceType, rootPartId } = inputObj.queryKey[1]
  const sourceSummaryAPIPath = `${API_URL}/api/dataSource/v1/${sourceType}?rootPartId=${rootPartId ?? ''}`
  const res = await axios.get(sourceSummaryAPIPath)
  return res.data
}

const getSpecificSourceSummary = async (inputObj) => {
  const { sourceType, dataSourceId } = inputObj.queryKey[1]
  const sourceSummaryAPIPath = `${API_URL}/api/dataSource/v1/${sourceType}?dataSourceId=${dataSourceId ?? ''}`
  const res = await axios.get(sourceSummaryAPIPath)
  return res.data
}


export function useBOMSourceSummary(queryKey, sourceType, rootPartId, setterFns) {
  const { setSheetData } = setterFns
  const options = {
    onSuccess: (data) => {
      setSheetData(data[0])
    },
    enabled: !!sourceType,
    ...rqDefaultOptions,
    refetchOnWindowFocus: false,
  }
  return useQuery([queryKey, { sourceType, rootPartId }], getSourceSummary, options)
}

export function useForecastSourceSummary(queryKey, sourceType, dataSourceId, setterFns) {
  const { setSheetData } = setterFns
  const options = {
    onSuccess: (data) => {
      setSheetData(data[0])
    },
    enabled: !!sourceType,
    ...rqDefaultOptions,
    refetchOnWindowFocus: false,
  }
  return useQuery([queryKey, { sourceType, dataSourceId }], getSpecificSourceSummary, options)
}

export function useDeliveriesSourceSummary(queryKey, sourceType, setterFns) {
  const { setSheetData } = setterFns
  const options = {
    onSuccess: (data) => {
      setSheetData(data[0])
    },
    enabled: !!sourceType,
    ...rqDefaultOptions,
    refetchOnWindowFocus: false,
  }
  return useQuery([queryKey, { sourceType }], getSourceSummary, options)
}

export function useProductionAcutalsSourceSummary(queryKey, sourceType, setterFns) {
  const { setSheetData } = setterFns
  const options = {
    onSuccess: (data) => {
      setSheetData(data[0])
    },
    enabled: !!sourceType,
    ...rqDefaultOptions,
    refetchOnWindowFocus: false,
  }
  return useQuery([queryKey, { sourceType }], getSourceSummary, options)
}

export function useInventorySourceSummary(queryKey, sourceType, setterFns) {
  const { setSheetData } = setterFns
  const options = {
    onSuccess: (data) => {
      setSheetData(data)
    },
    enabled: !!sourceType,
    ...rqDefaultOptions,
    refetchOnWindowFocus: false,
  }
  return useQuery([queryKey, { sourceType }], getSourceSummary, options)
}
