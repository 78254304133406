import React from 'react'
import axios from 'axios'
import { API_URL } from '../../../config'

import MaterialTableCustom from '../../../components/MaterialTableCustom'

const columns = [
  { field: 'partNumber', title: 'Part Number' },
  { field: 'dateOfRecord', title: 'Date', type: 'date' },

  {
    field: 'quantity',
    title: 'Quantity',
    type: 'numeric',
  },
]

const ForecastTable = ({ title, data, refreshTableData }) => {
  const addForecastRow = async (newData) => {
    const addDataAPIPath = `${API_URL}/api/forecast/v1/new`
    await axios.post(addDataAPIPath, newData).catch((err) => console.error(err))
    refreshTableData()
  }

  const editForecastItem = async (newData) => {
    const parsedData = {
      partNumber: newData.partNumber,
      dateOfRecord: newData.dateOfRecord,
      quantity: newData.quantity,
    }

    const editDataAPIPath = `${API_URL}/api/forecast/v1/update/${newData.id}`

    await axios.patch(editDataAPIPath, parsedData).catch((err) => console.error(err))

    refreshTableData()
  }

  const deleteForecastItem = async (oldData) => {
    const { id } = oldData
    const deleteDataAPIPath = `${API_URL}/api/forecast/v1/${id}`
    await axios.delete(deleteDataAPIPath).catch((err) => console.error(err))

    refreshTableData()
  }

  if (data === null) {
    return <>loading...</>
  }

  return (
    <MaterialTableCustom
      title={title}
      columns={columns}
      data={data}
      rowStyle={{
        fontSize: '14px',
      }}
      options={{
        filtering: false,
        search: true,
        maxBodyHeight: '500px',
        maxBodyWidth: '400px',
        paging: false,
        actionsColumnIndex: 3,
      }}
      editable={{
        onRowAdd: addForecastRow,
        onRowUpdate: editForecastItem,
        onRowDelete: deleteForecastItem,
      }}
    />
  )
}

export default ForecastTable
