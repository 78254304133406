import React from 'react'
import MaterialTableCustom from '../../../components/MaterialTableCustom'

const columns = [
  { field: 'dateOfRecord', title: 'Date', type: 'date' },
  { field: 'partNumber', title: 'Part Number' },
  {
    field: 'quantity',
    title: 'Quantity',
  },
]

const ProductActualTable = ({ data }) => {
  if (data === null) {
    return <>loading...</>
  }
  return (
    <MaterialTableCustom
      title={'Production Actuals'}
      columns={columns}
      rowStyle={{
        fontSize: '14px',
      }}
      data={data}
      options={{
        filtering: false,
        search: true,
        paging: false,
        actionsColumnIndex: 10,
        maxBodyHeight: '700px',
      }}
    />
  )
}

export default ProductActualTable
