import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import LoginAuth0 from './LoginAuth0'
import Routes from '../../Routes'
import { useHistory } from 'react-router-dom'
import { SKIP_AUTH } from '../../config'

export default function LoginGateway() {
  const { isAuthenticated, isLoading, error, logout } = useAuth0()
  const history = useHistory()

  if (SKIP_AUTH === 'true') return <Routes />
  if (error) {
    console.error(error)
    logout({ returnTo: `${window.location.origin}/home` })
  }

  const pathName = window.location.pathname

  if (isAuthenticated && pathName === '/') history.push(`${window.location.origin}/home`)
  if (isLoading) return null
  if (isAuthenticated) return <Routes />
  else return <LoginAuth0 />
}
