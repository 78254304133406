import React from 'react'
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress'

export default function GenericLoading() {
  return (
    <Container maxWidth='md' style={{ alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress />
    </Container>
  )
}
