import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import Paper from '@material-ui/core/Paper'
import clsx from 'clsx'

import SourceAccordianMain from './SourceAccordianMain'

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  sourceButton: {
    marginBottom: 20,
    marginTop: 10,
    height: 48,
    borderRadius: 15,
    width: 350,
  },
  heading: {
    fontSize: '1.2rem',
    fontWeight: 500,
  },
  accordianRoot: {
    borderRadius: 15,
    overflow: 'hidden',
    padding: 3,
    margin: '0px 0px 10px 0px',
  },
  accDeets: {
    padding: 20,
    paddingTop: 10,
    paddingLeft: 40,
  },
  tablePaper: {
    marginTop: 20,
    borderRadius: 15,
    padding: 10,
    paddingBottom: 0,
    paddingTop: 0,
    overflow: 'hidden',
    '& .MuiPaper-elevation2': {
      overflow: 'hidden',
      boxShadow: 'unset',
    },
    '& .MuiToolbar-gutters': {
      paddingLeft: 10,
      minHeight: 70,
    },
  },
  deleteSourceButton: {
    borderRadius: 10,
    width: 30,
    padding: 0,
    '& span': {
      padding: 0,
      margin: 0,
    },
    '& .MuiButton-label': {
      width: 30,
    },
  },
}))

export default function SourceAccordians({
  label,
  sources,
  displayComp,
  addNewSource = false,
  refetchData,
  clearSheetData,
  middleComp = false,
  toExpand,
  addToSourceFn = () => console.log('source'),
}) {
  const classes = useStyles()

  return (
    <Container className={classes.root}>
      <Button hidden={true} startIcon={<AddIcon />} />
      {Array.isArray(sources) && sources.map((sourceComp, i) => (
        
        <SourceAccordianMain toExpand={Array.isArray(toExpand) ? (toExpand[1] === i ? toExpand[0] : false) : toExpand} sourceComp={sourceComp} label={label} key={i} clearSheetData={clearSheetData} refetchData={refetchData}/>
      ))}
      {middleComp && (
        <Accordion defaultExpanded className={classes.accordianRoot}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
            <Grid container spacing={1} direction='row' justifyContent='space-between'>
              <Typography className={classes.heading}>{middleComp.title}</Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.accDeets}>{middleComp.comp}</AccordionDetails>
        </Accordion>
      )}
      <Grid container spacing={1} direction='column' justifyContent='center'>
        <Button
          className={classes.sourceButton}
          variant='contained'
          color='secondary'
          hidden={addNewSource ? false : true}
          startIcon={<AddIcon />}
          onClick={() => addToSourceFn(refetchData)}
        >
          Add Another {label} Source
        </Button>
      </Grid>
      {displayComp && (
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xs>
            <Paper className={clsx('mat-table-cont', classes.tablePaper)}>{displayComp}</Paper>
          </Grid>
        </Grid>
      )}
    </Container>
  )
}
