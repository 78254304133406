import React from 'react'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

const ProductSelection = ({ options, newOptionDescription, setOption, currentOption }) => {
  const newOption = {partDescription: newOptionDescription};
  return (
    <Autocomplete
        fullWidth
        disableClearable
        size='small'
        value={currentOption ?? newOption}
        options={newOptionDescription ? [newOption, ...options] : options}
        getOptionLabel={(option) => `${option.partNumber ?? ''} ${option.partDescription ?? ''}`}
        renderInput={(params) => <TextField fullWidth {...params} variant="outlined" />}
        onChange={(e, part) => setOption(part)}
        renderOption={(option) => {
          const label = `${option.partNumber ?? ''} ${option.partDescription ?? ''}`;
          return option.partDescription === newOptionDescription? <b>{label}</b> : label
        }}
    />
  )
}

export default ProductSelection;
