import { makeStyles, createStyles, Theme, createTheme } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'
import BackgroundImageSVG from '../../assets/icons/BackgroundImage.svg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      flexWrap: 'nowrap',
    },
    floatingTitle1: {
      color: '#1234AF',
      fontFamily: 'Montserrat',
      fontWeight: 800,
      fontStyle: 'normal',
      fontSize: 30,
      zIndex: 1000,
      position: 'absolute',
      width: 113,
      height: 37,
      left: 110,
      top: 100,

      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        left: '5%',
      },
    },
    floatingTitle2: {
      color: '#1234AF',
      fontFamily: 'Montserrat',
      fontWeight: 800,
      fontStyle: 'normal',
      fontSize: 45,
      zIndex: 1000,
      position: 'absolute',
      width: 332,
      height: 55,
      left: 109,
      top: 124,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        left: '5%',
      },
    },
    floatingTitle3: {
      color: '#727272',
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: 25,
      zIndex: 1000,
      width: 465,
      height: 30,
      left: 112,
      top: 177,
      position: 'absolute',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        left: '5%',
      },
    },
    image: {
      backgroundImage: `url(${BackgroundImageSVG})`,
      backgroundRepeat: 'no-repeat',
      backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      boxShadow: '0',
    },
    paper: {
      height: '100%',
      boxShadow: 'none',
      // position: 'relative',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    submit: {
      color: 'white',
      backgroundColor: 'black',
      borderRadius: 10,
      width: 200,
      '&:hover': {
        backgroundColor: grey[800],
      },
    },
  }),
)

export default useStyles
