import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { format } from 'date-fns'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 10,
    height: 135,
  },
  textDesc: {
    paddingLeft: 0,
    fontSize: '1.0rem',
  },
  textValue: {
    fontSize: '1.0rem',
    overflow: 'hidden',
  },
}))

export default function LastUploadedSection({
  fileName,
  lastImported,
  status,
  // statusMessage,
}) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={3} direction='column' justifyContent='flex-start'>
        <Grid item container direction='row' spacing={2}>
          <Grid item xs={3}>
            <Typography className={classes.textDesc}>File Name</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography className={classes.textValue}>{fileName}</Typography>
          </Grid>
        </Grid>
        <Grid item container direction='row' spacing={2}>
          <Grid item xs={3}>
            <Typography className={classes.textDesc}>Last Imported</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography className={classes.textValue}>
              {lastImported ? format(new Date(lastImported), `MM/dd/yyyy HH:mm:ss 'Z'-XXX`) : 'Not Imported Yet'}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction='row' spacing={2}>
          <Grid item xs={3}>
            <Typography className={classes.textDesc}>Status</Typography>
          </Grid>
          <Grid item xs={9}>
            {status && (
              <Typography className={classes.textValue}>
                {status}
                {/* {statusMessage ? ` - ${statusMessage}` : ``} */}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
