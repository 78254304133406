/* eslint-disable react/react-in-jsx-scope */
import { useQuery } from 'react-query'
import axios from 'axios'
import { API_URL } from '../../config'
import { rqDefaultOptions } from './defaultOptions'

const getCTBTable = async (inputObj) => {
  const { parentPart, includeDeliveries, weeksOut } = inputObj.queryKey[1]
  const clearToBuildTableAPIPath = `${API_URL}/api/ctb/v1/clearToBuildTable?parentPart=${parentPart.partNumber}&incDeliveries=${includeDeliveries}&weeksOut=${weeksOut}`

  const res = await axios.get(clearToBuildTableAPIPath).catch((err) => console.error(err))

  return res.data
}

export function useCTBTable(parentPart, includeDeliveries, weeksOut = 20, setCtbData, formatColumns) {
  const options = {
    enabled: !!parentPart,
    onSuccess: (resData) => {
      let { data = [], columns = [] } = resData
      columns = formatColumns(columns)
      
      let nz = 0
      for (let i = 0; i < data.length; i++) {
        if (data[i].partType) nz += 1
      }

      if (nz == 0) {
        columns.splice(2, 1)
        columns[1].cellStyle.maxWidth = 200
      } else {
        columns.splice(2, 0, { title: 'Type', field: 'partType' })
        columns[1].cellStyle.maxWidth = 135
      }

      setCtbData({
        data,
        columns: columns,
      })
    },
    ...rqDefaultOptions,
  }
  return useQuery(['ctbTable', { parentPart, includeDeliveries, weeksOut }], getCTBTable, options)
}
