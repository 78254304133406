import React, { useState } from 'react'
import BOMSourceData from '../sections/BOMSourceData'
import BomTable from './BomTable'
import SourceAccordians from './SourceAccordian/SourceAccordians'
import { useBOMSourceSummary } from '../../../hooks/queries/sourceSummary'
import { useQueryClient } from 'react-query'
import { usePartTable } from '../../../hooks/queries/parts/getPartTable'
import Loading from '../../../components/Loading'
import BOMExclusions from './BOMExclusions'

export const SingleBom = ({ rootPart, resetProductList }) => {
  const [sheetData, setSheetData] = useState({})
  const [expanded, setExpanded] = useState(false);

  const sourceSummaryQueryKey = 'bomSourceSummary'
  const sourceSummaryType = 'bomSummary'
  const dataSummaryQueryKey = 'partBOMTable'

  const { isLoading: iLPartTable, isError: iEPartTable, data: dataPartTable } = usePartTable(dataSummaryQueryKey, rootPart?.partId)

  const bomSourceSummaryQuery = useBOMSourceSummary(sourceSummaryQueryKey, sourceSummaryType, rootPart?.partId, {
    setSheetData,
  })
  const { isFetching: iFSourceData } = bomSourceSummaryQuery
  const clearSheetData = () => {
    setSheetData({});
    resetProductList();
  }

  const queryClient = useQueryClient()
  const refetchData = (selectNewProduct) => {
    queryClient.refetchQueries([sourceSummaryQueryKey]);
    queryClient.refetchQueries([dataSummaryQueryKey]);
    if (selectNewProduct) {
      resetProductList();
    }
  }

  const setExpand = (Val) => {
    setExpanded(Val);
  }

  const sourceDataSources = iFSourceData
    ? []
    : [<BOMSourceData refetchData={refetchData} uploadSummary={sheetData} queryObj={bomSourceSummaryQuery} key={0} setExpand={setExpand} />]
  const tableData = iLPartTable || iEPartTable ? [] : dataPartTable

  return (
    <>
      {iFSourceData ? (
        <Loading />
      ) : (
        <SourceAccordians
          label={'BOM'}
          refetchData={refetchData}
          clearSheetData={clearSheetData}
          sources={sourceDataSources}
          displayComp={<BomTable data={tableData} />}
          middleComp={{ title: 'Customize BOM', comp: <BOMExclusions currentParts={dataPartTable} /> }}
          addNewSource={false}
          toExpand={expanded}
        />
      )}
    </>
  )
}
