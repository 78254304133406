import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'

export const TitleOne = (props) => {
  let { className, heading } = props
  return (
    <Typography color='secondary' className={`${className}`} variant='h4'>
      {heading}
    </Typography>
  )
}
TitleOne.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
}

export const SectionHeader = ({ className, heading }) => {
  return (
    <Typography className={`${className}`} variant='h6'>
      {heading}
    </Typography>
  )
}
SectionHeader.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
}

export const TitleWithLine = ({ heading }) => {
  return (
    <div className='d-flex align-items-center mb-5'>
      <Typography className='dark-grey' variant='h6'>
        {heading}
      </Typography>
      <span className='flex-fill bg-dark mt-1' style={{ height: 0.5 }} />
    </div>
  )
}
TitleWithLine.propTypes = {
  heading: PropTypes.string,
}
