// import { useState } from 'react'
import { useQuery } from 'react-query'
import axios from 'axios'
import { API_URL } from '../../config'
import { rqDefaultOptions } from './defaultOptions'
import { useAuth0 } from '@auth0/auth0-react'

const getForecastsDataSources = async (email) => {
  const getAllForecastDataSourcesAPIPath = `${API_URL}/api/forecast/v1/getAllForecastDataSources`
  axios.defaults.headers.common['Authorization'] = email;
  const res = await axios.get(getAllForecastDataSourcesAPIPath).catch((err) => {
    throw err
  })
  return res.data
}

export function useForecastDataSources(setForecastDataSource, setForecastDataSourceList, email) {
  const options = {
    onSuccess: (data) => {
      setForecastDataSourceList(data)
      if (data.length) setForecastDataSource(data[data.length-1])
    },
    ...rqDefaultOptions,
  }
  const ppQuery = useQuery(['forecastDataSources'], () => getForecastsDataSources(email), options)
  return ppQuery
}
