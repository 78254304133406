import axios from 'axios'
import React, { useState } from 'react'

import Grid from '@material-ui/core/Grid'
import UploadSection from './UploadSection'
import LastUploadedSection from '../components/LastUploadedSection'
import { makeStyles } from '@material-ui/core/styles'
import RegularColumnSelection from '../components/RegularColumnSelection'

import { secondaryFormatter } from './utils'
import { hasAllRequiredColumnValues } from './SourceDataMethods'
import { API_URL } from '../../../config'
import { firstLetterUpper } from '../../../util/stringTransform'
import toast from 'react-hot-toast'
import { logError } from '../../../util/logger'

const useStyles = makeStyles(() => ({
  breakLine: {
    margin: 10,
    marginTop: 20,
    marginBottom: 20,
  },
  // root: {},
}))

const ActualsSourceData = ({ uploadSummary, refetchData }) => {
  const classes = useStyles()
  const currentColumnRef = uploadSummary?.columnRef
  const currentSheetSelected = uploadSummary?.sheetNameToSelect
  const currentUploadedFile = uploadSummary?.fileName
  const lastImported = uploadSummary?.lastImported
  const fileStructure = uploadSummary?.fileStructure
  const fileToken = uploadSummary?.fileToken
  const status = uploadSummary?.status
  const statusMessage = uploadSummary?.statusMessage

  const [sheet, setSheet] = useState(currentSheetSelected || '')
  const [currentColumnList, setCurrentColumnList] = useState(currentSheetSelected ? secondaryFormatter(fileStructure, currentSheetSelected) : [])
  const [partNumber, setPartNumber] = useState(currentColumnRef?.partNumber || '')
  const [dateOfRecord, setDateOfRecord] = useState(currentColumnRef?.dateOfRecord || '')
  const [quantity, setQuantity] = useState(currentColumnRef?.quantity || '')

  const [disableSave, setDisableSave] = useState(true)

  const setColumnData = (inputSheet, sheet) => {
    setCurrentColumnList(secondaryFormatter(inputSheet.fileStructure, sheet))
  }

  const changeSheet = (sheetName) => {
    if (sheetName === '') {
      setCurrentColumnList([])
    } else setColumnData(uploadSummary, sheetName)
    setSheet(sheetName)
  }

  const criticalColumnConfig = [
    {
      label: 'Part Number',
      ref: partNumber,
      refSet: setPartNumber,
      required: true,
    },
    {
      label: 'Date',
      ref: dateOfRecord,
      refSet: setDateOfRecord,
      required: true,
    },
    {
      label: 'Actual Build Qty',
      ref: quantity,
      refSet: setQuantity,
      required: true,
    },
  ]
  const allRequiredFieldsSelected = hasAllRequiredColumnValues(criticalColumnConfig)
  const handleFieldChange = (setStateFn, value) => {
    setStateFn(value)
    if (disableSave) setDisableSave(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const setActualsColumnRef = `${API_URL}/api/dataSource/v1/setActualsColumnRef`
    const data = {
      fileToken: fileToken,
      cleanImport: true,
      sheetName: sheet,
      columnRef: {
        partNumber: partNumber,
        dateOfRecord: dateOfRecord,
        quantity: quantity,
      },
    }

    const updateActualsColumnRef = axios
      .post(setActualsColumnRef, data)
      .then(async () => {
        await refetchData()
        setDisableSave(true)
      })
      .catch((err) => {
        toast.error('Somethign went wrong while trying to save your new selection. Please try again')
        logError(err.message, err.stack)
      })

    toast.promise(updateActualsColumnRef, {
      loading: <div>{`What actually was made? We'll find out after we process your new actuals.`}</div>,
      success: <b>Done!</b>,
      error: <b>We had an issue saving your changes, please try again!</b>,
    })
  }

  return (
    <Grid container spacing={2} direction='column' className={classes.root}>
      <UploadSection uploadDataType='actuals' refetchSourceInfoFn={refetchData} />
      <hr className={classes.breakLine} />

      <LastUploadedSection
        fileName={currentUploadedFile}
        lastImported={lastImported}
        status={firstLetterUpper(status)}
        statusMessage={statusMessage}
        label={`Actuals`}
      />
      <hr className={classes.breakLine} />
      <RegularColumnSelection
        config={criticalColumnConfig}
        changeSheet={changeSheet}
        currentSheet={sheet}
        fileStructure={fileStructure}
        currentColumnList={currentColumnList}
        handleFieldChange={handleFieldChange}
        handleSubmit={handleSubmit}
        allRequiredFieldsSelected={allRequiredFieldsSelected}
        disableSave={disableSave}
      />
    </Grid>
  )
}

export default ActualsSourceData
