import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import SourceIcons from '../../../assets/icons'
import { TitleWithLine } from '../../../components/Title'
import { Grid, Typography } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { API_URL } from '../../../config'

import GenericLoading from '../../../components/GenericLoading'

const useStyles = makeStyles(() => ({
  cardTitle: {
    color: '#000000',
  },
  cardDesc: {
    color: '#5A6268',
  },
  cardIcon: {},
}))

const OverallSourceSummary = () => {
  const classes = useStyles()

  const [importStatusResponse, setStatus] = useState(null)

  useEffect(() => {
    const importStatusAPIPath = `${API_URL}/api/dataSource/v1/importStatus`
    axios
      .get(importStatusAPIPath)
      .then((res) => setStatus(res.data))
      .catch((err) => console.log(err))
  }, [])

  const data = () => [
    {
      link: '/source-data/bill',
      icon: SourceIcons.Bill,
      title: 'Bill Of Materials',
      text: `BOM table defining the relationship between sub-assemblies and components`,
      spanItem:
        importStatusResponse.billOfMaterials === 'verified' ? (
          <CheckIcon />
        ) : importStatusResponse.billOfMaterials === 'Issue' ? (
          <PriorityHighIcon />
        ) : importStatusResponse.billOfMaterials === 'Error' ? (
          <CloseIcon />
        ) : (
          <CloseIcon />
        ),
      color:
        importStatusResponse.billOfMaterials === 'verified'
          ? `#42A744`
          : importStatusResponse.billOfMaterials === 'Issue'
            ? `#FFCA83`
            : importStatusResponse.billOfMaterials === 'Error'
              ? `#EC5E5E`
              : `#dddddd`,
    },
    {
      link: '/source-data/inventory',
      icon: SourceIcons.Inventory,
      title: 'Inventory',
      text: `Periodically refreshed inventory and WIP levels from one or more sources`,
      spanItem:
        importStatusResponse.inventory === 'verified' ? (
          <CheckIcon />
        ) : importStatusResponse.inventory === 'Issue' ? (
          <PriorityHighIcon />
        ) : importStatusResponse.inventory === 'Error' ? (
          <CloseIcon />
        ) : (
          <CloseIcon />
        ),
      color:
        importStatusResponse.inventory === 'verified'
          ? `#42A744`
          : importStatusResponse.inventory === 'Issue'
            ? `#FFCA83`
            : importStatusResponse.inventory === 'Error'
              ? `#EC5E5E`
              : `#dddddd`,
    },
    {
      link: '/source-data/forecast',
      icon: SourceIcons.Forecast,
      title: 'Forecast',
      text: `Forecast of daily or weekly production targets of products`,
      spanItem:
        importStatusResponse.forecast === 'verified' ? (
          <CheckIcon />
        ) : importStatusResponse.forecast === 'Issue' ? (
          <PriorityHighIcon />
        ) : importStatusResponse.forecast === 'Error' ? (
          <CloseIcon />
        ) : (
          <CloseIcon />
        ),
      color:
        importStatusResponse.forecast === 'verified'
          ? `#42A744`
          : importStatusResponse.forecast === 'Issue'
            ? `#FFCA83`
            : importStatusResponse.forecast === 'Error'
              ? `#EC5E5E`
              : `#dddddd`,
    },
  ]

  const dataTwo = () => [
    {
      link: '/source-data/deliveries',
      icon: SourceIcons.Deliveries,
      title: 'Deliveries',
      text: `Shipment schedule of sourced components and sub-assemblies`,
      spanItem:
        importStatusResponse.deliveries === 'verified' ? (
          <CheckIcon />
        ) : importStatusResponse.deliveries === 'Issue' ? (
          <PriorityHighIcon />
        ) : importStatusResponse.deliveries === 'Error' ? (
          <CloseIcon />
        ) : (
          <CloseIcon />
        ),
      color:
        importStatusResponse.deliveries === 'verified'
          ? `#42A744`
          : importStatusResponse.deliveries === 'Issue'
            ? `#FFCA83`
            : importStatusResponse.deliveries === 'Error'
              ? `#EC5E5E`
              : `#dddddd`,
    },
    {
      link: '/source-data/productionActual',
      icon: SourceIcons.Actual,
      title: 'Production Actuals',
      text: `Actual production quantities to compare with planned quantity`,
      spanItem:
        importStatusResponse.actuals === 'verified' ? (
          <CheckIcon />
        ) : importStatusResponse.actuals === 'Issue' ? (
          <PriorityHighIcon />
        ) : importStatusResponse.actuals === 'Error' ? (
          <CloseIcon />
        ) : (
          <CloseIcon />
        ),
      color:
        importStatusResponse.actuals === 'verified'
          ? `#42A744`
          : importStatusResponse.actuals === 'Issue'
            ? `#FFCA83`
            : importStatusResponse.actuals === 'Error'
              ? `#EC5E5E`
              : `#dddddd`,
    },
    {
      link: '/source-data/other',
      icon: SourceIcons.Box,
      title: 'Other Information',
      text: `Other useful inputs such as part number mapping keys and lead time tables`,
      spanItem:
        importStatusResponse.otherInformation === 'verified' ? (
          <CheckIcon />
        ) : importStatusResponse.otherInformation === 'Issue' ? (
          <PriorityHighIcon />
        ) : importStatusResponse.otherInformation === 'Error' ? (
          <CloseIcon />
        ) : (
          <CloseIcon />
        ),
      color:
        importStatusResponse.otherInformation === 'verified'
          ? `#42A744`
          : importStatusResponse.otherInformation === 'Issue'
            ? `#FFCA83`
            : importStatusResponse.otherInformation === 'Error'
              ? `#EC5E5E`
              : `#dddddd`,
    },
  ]

  return (
    <>
      {!importStatusResponse ? (
        <GenericLoading />
      ) : (
        <>
          <div className='mb-5'>
            <TitleWithLine heading='Required Inputs' />
            <Grid container className='justify-content-center justify-content-xl-around '>
              {data().map((data, index) => (
                <div className='p-3 sourceSummaryBox' key={`${index}`}>
                  <Link
                    to={data.link}
                    className='h-100 box bg-white rounded-lg shadow-sm px-3 py-4 text-center text-decoration-none position-relative'
                    style={{ width: 310 }}
                  >
                    <img className='mb-2' height={40} src={data.icon} alt='' />
                    <Typography variant='h6' className={clsx('mb-2', classes.cardTitle)}>
                      {data.title}
                    </Typography>
                    <Typography className={clsx('m-0', classes.cardDesc)} style={{ fontSize: 14 }}>
                      {data.text}
                    </Typography>
                    <span className={`rounded-circle text-white  ${data.spanClassName}`} style={{ backgroundColor: `${data.color}` }}>
                      {data.spanItem}
                    </span>
                  </Link>
                </div>
              ))}
            </Grid>
          </div>
          <div>
            <TitleWithLine heading='Additional Inputs' />
            <Grid container className='justify-content-center justify-content-xl-around '>
              {dataTwo().map((data, index) => (
                <div className='p-3 sourceSummaryBox' key={`${index}`}>
                  <Link
                    to={data.link}
                    className='h-100 box bg-white rounded-lg shadow-sm px-3 py-4 text-center text-decoration-none position-relative'
                    style={{ width: 310 }}
                  >
                    <img className='mb-2' height={40} src={data.icon} alt='' />
                    <Typography variant='h6' className={clsx('mb-2', classes.cardTitle)}>
                      {data.title}
                    </Typography>
                    <Typography className={clsx('m-0', classes.cardDesc)} style={{ fontSize: 14 }}>
                      {data.text}
                    </Typography>
                    <span className={`rounded-circle text-white ${data.spanClassName}`} style={{ backgroundColor: `${data.color}` }}>
                      {data.spanItem}
                    </span>
                  </Link>
                </div>
              ))}
            </Grid>
          </div>
        </>
      )}
    </>
  )
}

export default OverallSourceSummary
