import 'react'
import { useMutation } from 'react-query'
import axios from 'axios'
import { API_URL } from '../../config'

export const updateDeliveryItem = async (updateDeliveryItem) => {
  const { id, ...changedFields } = updateDeliveryItem

  if (!id) throw 'No Id Supplied'
  //MAKING THE UPDATE API CALL
  const updateDeliveryItemAPIPath = `${API_URL}/api/delivery/v1/update/${id}`
  const res = await axios.patch(updateDeliveryItemAPIPath, changedFields);
  return res.data
}

export function useUpdateDelivery() {
  return useMutation(updateDeliveryItem)
}

export const addDeliveryItem = async (newItem) => {
  const addDeliveryItemAPIPath = `${API_URL}/api/delivery/v1/new`
  return await axios.post(addDeliveryItemAPIPath, newItem)
}

export function useAddDelivery() {
  return useMutation(addDeliveryItem)
}

export const deleteDeliveryItem = async ({ id }) => {
  if (!id) throw 'Supply Id to delete'
  const deleteDeliveryItemAPIPath = `${API_URL}/api/delivery/v1/${id}`
  return await axios.delete(deleteDeliveryItemAPIPath)
}

export function useDeleteDelivery() {
  return useMutation(deleteDeliveryItem)
}
